 <!-- BEGIN LOGO -->
<div class="logo">
    <a routerLink = "/">
        <img [src]="wLogo" alt="{{siteName}} Logo" />
    </a>
</div>
<!-- END LOGO -->
<!-- BEGIN LOGIN -->
<div class="content mt-1" >
    <router-outlet></router-outlet>
</div>
<div class="copyright font-white"> 2020 © <a href="{{website}}" target="_blank" class="font-white">{{siteName}} Admin Site</a> </div>