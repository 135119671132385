import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl } from "@angular/forms";
import { Http, Response, Headers } from '@angular/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';
import * as $ from 'jquery';
import { AngularEditorConfig } from '@kolkov/angular-editor';

import { MassModel, MassService, 
		ChurchModel, ChurchService,
    VideoTypeService, VideoTypeModel,
		MediaService, MediaModel,
		LibsService, PagerService, MysqlService, LocalData } from '../_services';

@Component({
  selector: 'app-masses',
  templateUrl: './masses.component.html',
  styleUrls: ['./masses.component.scss']
})
export class MassesComponent implements OnInit {
  mass: MassModel = new MassModel();
  masses = [];
  key ='';

  form: FormGroup;
  requiredFields = [];
  isValidName = true;
  isValidCity = true;
  isValidState =  true;

  btnText = 'Submit';
  title = 'Add';
  isModal = 'none';

  states = this._localDB.states;
  timeZone = this._localDB.timeZone;

  videoTypes = [];
  videoType: VideoTypeModel = new VideoTypeModel();
  isType = false;

  churches = [];

  modalId = '';
  backpage = '';

  pager: any = {}; // pager object
  pagedItems: any[]; // paged items
  pageSize = 12;
  currentPage = 1;

  editorConfig: AngularEditorConfig = {
    editable: true,
      spellcheck: true,
      height: 'auto',
      minHeight: '100px',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes'
  }

  constructor(
    private _localDB: LocalData,
    private _libs: LibsService,
    private videoTypeService: VideoTypeService,
    private pagerService: PagerService,
  	private massService: MassService,
  	private churchService: ChurchService,
  	private formBuilder: FormBuilder,
  ) { 
    this.masses = this._libs.getLocalStorageItem('masses');
    this.churches = this._libs.getLocalStorageItem('churches');
    this.videoTypes = this._libs.getLocalStorageItem('videoTypes');
    this.form = this.formBuilder.group({
      name: new FormControl('', [Validators.minLength(2), Validators.required]),
      churchId: new FormControl(''),
      videotype: new FormControl('', Validators.required),
      link: new FormControl(''),
      date: new FormControl('', Validators.required),
      time: new FormControl('', Validators.required),
      timezone: new FormControl('', Validators.required),
      live: false,
    });
  }

  ngOnInit(): void {
    this. requiredFields = this._libs.makeRequirementField();
    if(this.videoTypes.length == 0){
      this.getVideoTypesList();
    }
    // console.log(this.videoTypes)
    if (this.churches.length > 0){
      if (this.masses.length > 0){
	      this.getChurchInfo4Mass();
      }else{
      	this.getMassesList();
      }
    }else{
      this.getChurchesList();
    }
    this.setPage(this.currentPage);
    $('input').on('input', function(e) {
  	  if ($(this).parent().hasClass('required')){
  	  	if ($(this).parent().hasClass('error') && $('#'+e.currentTarget.id).val() != ''){
  		  	$(this).parent().removeClass('error');
  		  	$(this).parent().find('.error-label').remove();
  	  	}
  	  	if (!$(this).parent().hasClass('error') && $('#'+e.currentTarget.id).val() == ''){
      	  	$(this).parent().addClass('error');
              $(this).parent().append('<span class="form-text text-muted font-red small error-label">This field is required.</span>');
  	  	}
  	  }
  	});
	
    //DATE FORMATTER
  	var date = document.getElementById('date');
  	function checkValue(str, max) {
  	  if (str.charAt(0) !== '0' || str == '00') {
  	    var num = parseInt(str);
  	    if (isNaN(num) || num <= 0 || num > max) num = 1;
  	    str = num > parseInt(max.toString().charAt(0)) && num.toString().length == 1 ? '0' + num : num.toString();
  	  };
  	  return str;
  	};
  	date.addEventListener('input', function(e) {
  	  var input = $(this).val().toString();
  	  if (/\D\/$/.test(input)) input = input.substr(0, input.length - 3);
  	  var values = input.split('/').map(function(v) {
  	    return v.replace(/\D/g, '')
  	  });
  	  if (values[0]) values[0] = checkValue(values[0], 12);
  	  if (values[1]) values[1] = checkValue(values[1], 31);
  	  var output = values.map(function(v, i) {
  	    return v.length == 2 && i < 2 ? v + '/' : v;
  	  });
  	  $(this).val(output.join('').substr(0, 14));
  	});

	// date.addEventListener('blur', function(e) {
	//   var input = $(this).val().toString();
	//   var values = input.split('/').map(function(v, i) {
	//     return v.replace(/\D/g, '')
	//   });
	//   var output = '';
	//   console.log(values.length)
	//   if (values.length == 3) {
	//     var year = values[2].length !== 4 ? parseInt(values[2]) + 2000 : parseInt(values[2]);
	//     var month = parseInt(values[0]) - 1;
	//     var day = parseInt(values[1]);
	//     var d = new Date(year, month, day);
	//     console.log(d)
	//     // if (!isNaN(d)) {
	//     //   // $(this).parent().append('<span class="form-text text-muted font-red small error-label">'+d.toString()+'</span>');
	//     //   var dates = [d.getMonth() + 1, d.getDate(), d.getFullYear()];
	//     //   output = dates.map(function(v) {
	//     //     v = v.toString();
	//     //     return v.length == 1 ? '0' + v : v;
	//     //   }).join(' / ');
	//     // };
	//   };
	//   $(this).val(output);
	// });
    //END DATE FORMATTER

  }

  getMassesList(){
  	this.massService.getMassesList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ id: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(masses => {
      this.masses = masses.sort(this._libs.dynamicSortByNameSort('timestamp', 'asc'));
      this._libs.setLocalStorageItem('masses', this.masses);
      // console.log(this.masses);
      this.getChurchInfo4Mass();
    });
  }

  getChurchInfo4Mass(){
    for (var i = 0; i < this.masses.length; ++i) {
      var temp = this.churches.filter(ft => ft['id'] == this.masses[i]['churchId']);
      this.masses[i]['church'] = temp[0];
    }
    this.setPage(this.currentPage);
  }

  // liveStream(e){
  //   console.log(e)
  //   this.mass['live'] = e;
  // }

  onSubmit() {
  	if (this._libs.validatorFields(this.requiredFields)){
  		return
  	}
	  var tempDate = (this.mass['date'].replace(/ /g,''));
	  this.mass['timestamp'] = new Date(tempDate+' '+this.mass['time']).getTime();
    // console.log(this.mass);
    this.mass = this._libs.convert2MassModel(this.mass);
    // console.log(this.key);
    if (this.key){
      this.update(this.mass);
    }else{
      this.save(this.mass);
    }
  }

  update(f){
    this.massService.updateMass(this.key, f);
    this.reset();
  }

  save(f) {
    this.massService.createMass(f);
    this.reset();
  }

  reset(){
    this.getMassesList();
    this.title = 'Add';
    this.btnText = 'Submit';
    this.mass = new MassModel();
    this.key = '';
  }

  massEdit(id){
    this.btnText = 'Update';
    this.title = 'Edit';
    this.mass = this.getMass(id);
  }

  getMass(id){
    let tempMass = new MassModel();
    this.key = id;
    var temp = this.masses.filter(ft => ft['id'] == id);
    return temp[0];
  }

  activeMass(id){
    var isChecked = $('#'+id).prop("checked");
    // console.log(isChecked);
    var tempMass = this.getMass(id);
    tempMass = this._libs.convert2MassModel(tempMass);
    tempMass['active'] = isChecked;
    this.massService.updateMass(id, tempMass);
  }

  massDelete(id, modalId){
    this.modalId = modalId;
    this.mass = this.getMass(id);
    this.isModal = this._libs.openModal(modalId);
  }

  searchMass(e){
    // console.log(e);
    var text = e.toLowerCase();
    if (e.length > 0){
      var temp = [];
      for (var i = 0; i < this.masses.length; ++i) {
        var tempDevs = (this.masses[i]['name']).toLowerCase();
        if (tempDevs != undefined && tempDevs != ''){
          if (tempDevs.indexOf(e) >= 0){
            temp.push(this.masses[i]);
          }
        }
      }
      this.masses = temp;
    }else{
      this.masses = this._libs.getLocalStorageItem('masses');
    }
    this.setPage(this.currentPage);
  }

  confirmYes(){
    this.massService.deleteMass(this.key);
    this.getMassesList();
    this.key = '';
    this.closeModal();
  }

  goBack(){
    this._libs.go2Page(this.backpage);
  }

  //VIDEO TYPE 
  getVideoTypesList(){
    this.videoTypeService.getVideoTypesList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ id: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(VideoTypes => {
      this.videoTypes = VideoTypes.sort(this._libs.dynamicSortByName('name'));
      this._libs.setLocalStorageItem('videoTypes', this.videoTypes);
    });
  }

  getVideoType(id){
    let tempVT = new VideoTypeModel();
    var temp = this.videoTypes.filter(ft => ft['id'] == id);
    return temp[0];
  }

  selectVideoType(e){
    this.videoType = this.getVideoType(e.target.value);
    this.isType = true;
  }

  //CHURCH 
  getChurchesList(){
  	this.churchService.getChurchesList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ id: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(churches => {
      this.churches = churches.sort(this._libs.dynamicSortByName('name'));
      this._libs.setLocalStorageItem('churches', this.churches);
      // console.log(this.churches);
      if (this.masses.length > 0){
	      this.getChurchInfo4Mass();
      }else{
      	this.getMassesList();
      }
    });
  }

  getChurch(id){
    let tempChurch = new ChurchModel();
    this.key = id;
    var temp = this.churches.filter(ft => ft['id'] == id);
    return temp[0];
  }
  
  ///MODAL 
  closeModal(){
    this.modalId = '';
    this.isModal = this._libs.closeModal();
    this.mass = new MassModel();
  }

  ///PAGE
  setPage(page: number) {
      // get pagerCarriers object from service
      this.pager = this.pagerService.getPager(this.masses.length, page, this.pageSize);
      // console.log(this.pagerCarriers);
      this.currentPage = page;
      // get current page of items
     this.pagedItems = this.masses.slice(this.pager.startIndex, this.pager.endIndex + 1);
      // console.log(this.pagedItems);
  }

}