import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ChurchModel, ChurchService, 
		  MassModel, MassService,
		  LibsService, PagerService, MysqlService, LocalData } from '../_services';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  churches = [];
  masses = [];

  constructor(
    private _libs: LibsService,
  	private massService: MassService,
  	private churchService: ChurchService,
  ) { 
    this.churches = this._libs.getLocalStorageItem('churches');
    this.masses = this._libs.getLocalStorageItem('masses');
  }

  ngOnInit(): void {
  	if (this.churches.length == 0){
  		this.getChurchesList();
  	}else{
  		if (this.masses.length == 0){
  			this.getMassesList();
  		}else{
  			this.getChurchInfo4Mass();
  		}
  	}
  }

  //CHURCH
  getChurchesList(){
  	this.churchService.getChurchesList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ id: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(churches => {
      this.churches = churches.sort(this._libs.dynamicSortByName('name'));
      this._libs.setLocalStorageItem('churches', this.churches);
      // console.log(this.churches);
      if (this.masses.length == 0){
		this.getMassesList();
  	  }else{
		this.getChurchInfo4Mass();
  	  }
    });
  }

  getChurch(id){
    let tempChurch = new ChurchModel();
    var temp = this.churches.filter(ft => ft['id'] == id);
    return temp[0];
  }

  viewChurch(id){

  }

  searchChurch(e){

  }

  //MASS
  getMassesList(){
  	this.massService.getMassesList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ id: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(masses => {
      this.masses = masses.sort(this._libs.dynamicSortByName('name'));
      this._libs.setLocalStorageItem('masses', this.masses);
      // console.log(this.masses);
      this.getChurchInfo4Mass();
    });
  }

  getChurchInfo4Mass(){
  	for (var i = 0; i < this.masses.length; ++i) {
  		this.masses[i]['church'] = this.getChurch(this.masses[i]['churchId'])
  	}
  }

}
