import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Http, Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';

import { AngularFirestore, AngularFirestoreDocument } from 'angularfire2/firestore';
import { AngularFireDatabaseModule, AngularFireDatabase, AngularFireList, AngularFireObject } from 'angularfire2/database';
import * as firebase from 'firebase/app';

import { CompanyModel } from './company.model';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  // infoRef: AngularFireObject<ULModel> = null;
  infoRef: AngularFireList<CompanyModel> = null;


  constructor(
  	private router: Router,
    private afStore: AngularFirestore,
    private afData: AngularFireDatabase,
  ) {
  	// this.infoRef = afData.object('/urbanliving');
    this.infoRef = afData.list('/infor');

  }
  getCompanyInfo(): AngularFireList<CompanyModel> {
    return this.infoRef;
  }

  updateInfo(key, values) {
    this.infoRef.set(key, values);
  }

  createInfo(value: CompanyModel): void {
    // this.infoRef.update(value);
    this.infoRef.push(value);
  }

  deleteInfo(): void {
    this.infoRef.remove().catch(error => this.handleError(error));
  }
  
  private handleError(error) {
    console.log(error);
  }

}
