import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl } from "@angular/forms";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TypeModel, TypeService, LibsService } from '../../_services';

@Component({
  selector: 'app-type',
  templateUrl: './type.component.html',
  styleUrls: ['./type.component.scss']
})
export class TypeComponent implements OnInit {

  types = [];
  type: TypeModel = new TypeModel();
  form: FormGroup;
  isValidName = true;
  isValidCode = true;
  submitted = false;
  isModal = 'none';
  modalId = '';
  ptKey = '';
  btnText = 'Submit';
  title = 'Add';

  constructor(
  	private typeService: TypeService,
  	private fb: FormBuilder,
    private _libs: LibsService,
  ) { }

  ngOnInit() {
  	this.createForm();
  	this.getTypesList();
  }

  getTypesList(){
  	this.typeService.getTypesList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ id: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(types => {
      this.types = types.sort(this._libs.dynamicSortByName('name'));
      // console.log(this.types);
    });
  }

  getType(id){
    this.ptKey = id;
    var temp = this.types.filter(pt => pt['id'] == id);
    return this.type = temp[0];
  }

  createForm() {
    this.form = this.fb.group({
      name: new FormControl('', Validators.required),
      code: new FormControl(''),
      icon: new FormControl(''),
    });
  }

  onSubmit() {
    let name = this.form.controls['name'].value;
    let code = this.form.controls['code'].value;
  	
  	if(name == '' || name == null){
  		this.isValidName = false
  		return;
  	}else{
  		this.isValidName = true;
  	}
    if(code == '' || code == null){
      this.isValidCode = false
      return;
    }else{
      this.isValidCode = true;
    }
    

    this.submitted = true;

    this.type = this.form.value;
    if (this.ptKey){
      
      this.update(this.type);
    }else{
      this.save(this.type);
    }
  }

  update(f){
    this.typeService.updateType(this.ptKey, f);
    this.reset();
  }

  save(f) {
    this.typeService.createType(f);
    this.reset();
  }

  reset(){
	this.title = 'Add';
	this.btnText = 'Submit';
    this.type = new TypeModel();
    this.form.reset();
    this.ptKey = '';
  }

  typeEdit(id){
    this.btnText = 'Update';
    this.title = 'Edit';
    this.type = this.getType(id);
  }

  typeDelete(id, modalId){
    this.modalId = modalId;
    this.type = this.getType(id);
    this.isModal = this._libs.openModal(modalId);
  }

  confirmYes(){
    // console.log(this.type['id']);
    this.typeService.deleteType(this.type['id']);
    this.closeModal();
  }

  closeModal(){
    this.modalId = '';
    this.type = new TypeModel();
    this.isModal = this._libs.closeModal();
  }

}
