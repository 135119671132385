import { Component, OnInit } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import { FormBuilder, FormGroup, FormControl , Validators } from '@angular/forms';

import { AuthenticationService, md5, LibsService, LocalData,
          UserService, UserModel } from '../../_services';

import * as $ from 'jquery';
import * as firebase from 'firebase/app';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  submitted = false;
  loading = false;
  isLogged = true;
  alertMessage = '';
  loginForm: FormGroup;

  user: UserModel = new UserModel();

  constructor(
  	private formBuilder: FormBuilder,
    private authService: AuthenticationService,
    private _libs: LibsService,
    private _localDB: LocalData,

  ) { 
  	$('body').addClass('login');

  	this.loginForm = this.formBuilder.group
	  ({
      email: ['', Validators.required],
      password: ['', Validators.required]
  	});
  }

  ngOnInit(): void {
  }

  isEmail(control: FormControl): {[s: string]: boolean} {
    if (!control.value.match(/^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/)) {
        return {noEmail: true};
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  onLoginFormSubmit(){
  	// console.log(this.f);
  	this.submitted = true;

    if (this.loginForm.invalid) {
        return;
    }
    this.loading = true;

    this.authService.doEmailLogin(this.f.email.value, this.f.password.value)
    .then(user => {
    	// console.log(user);
      this.isLogged = true;
      this.getCurrentUser(user.uid);
    }, err => {
      // console.log(err);
      this.alertMessage = err.message;
    });
  }

  getCurrentUser(uid){
    const usersRef: firebase.database.Reference = firebase.database().ref('/users/'+uid);
    usersRef.on('value', userSnapshot => {
      this.user = this._libs.convert2UserModel(userSnapshot.val());
      // console.log(this.user);
      if (this.user['roleId'] != '-LTYayzXFuFjQKDqvWIh'){
        this.user['password'] = '';
        this.user['id'] = this._libs.convert2NewIdString(uid);
        this.user['tempPass'] = md5(this.user['password']);
        // localStorage.setItem('currentUser', JSON.stringify(this.user));
        sessionStorage.setItem('currentUser', JSON.stringify(this.user));
        this._libs.go2Page('dashboard');  
      }else{
        window.location.href = this._localDB.website;
      }
    });
  }

  onkeyPress(e){
  // console.log(e.currentTarget.value);
  if (e.key === "Enter"){
    if (e.currentTarget.id == 'email' && e.currentTarget.value != ''){
      $('#password').focus();
    }else if (e.currentTarget.id == 'password' && e.currentTarget.value != ''){
      this.onLoginFormSubmit();
    }else{
      $('#'+e.currentTarget.id).focus();
      this.submitted = true;
      this.loginForm.invalid;
    }
  }else{
    this.isLogged = true;
  }
}

}
