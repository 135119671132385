import { Component, OnInit } from '@angular/core';
import { LibsService, LocalData } from '../../_services';

@Component({
  selector: 'app-site-layout',
  templateUrl: './site-layout.component.html',
  styleUrls: ['./site-layout.component.scss']
})
export class SiteLayoutComponent implements OnInit {
  website = this._localDB.website;
  siteName = this._localDB.sitename;
  wLogo = this._localDB.wlogo;

  constructor(
  	private _libs: LibsService,
    private _localDB: LocalData,
  ) { }

  ngOnInit(): void {
  }

}
