<div class="row">
    <!-- BEGIN: ADD/EDIT -->
	<div class="col-md-12 col-sm-12 col-xs-12">
		<div class="portlet light bordered">
			<div class="portlet-title">
	            <div class="caption">
	                <i class="icon-microphone font-dark hide"></i>
	                <span class="caption-subject bold font-dark uppercase">'{{siteName}}' Site Information</span>
	            </div>
	            <div class="actions">
                    <div class="btn-group d-none hide">
                        <label class="btn blue btn-outline btn-sm c-margin-l-5">
                            <span class="" (click)="openMedia('addMedia','null')" > Add Media </span>
                        </label>
                        <label class="btn green btn-outline btn-sm c-margin-l-5" *ngIf="addCategory">
                            <span class="" (click)="openModal('addCategoryModal')" > Add Category </span>
                        </label>
                    </div>
                </div>
	        </div>
            <div class="portlet-body form">
                <!-- <form role="form">  -->
                    <div class="form-body">
	                    <div class="row">
	                    	<div class="col-md-4 col-xs-12">
		                    	<div class="form-group">
			                        <label>Company Name <span class="font-red" *ngIf="!isValidName" >(*)</span></label>
			                        <input type="text" class="form-control" placeholder="Company Name" name="name" id="name" [(ngModel)]="company.name"> 
			                    </div>
	                    	</div>
                        </div>
                        <div class="row">
	                    	<div class="col-md-4 col-sm-8 col-xs-12">
		                    	<div class="form-group">
			                        <label>Address <span class="font-red" *ngIf="!isValidAddress1" >(*)</span></label>
			                        <input type="text" class="form-control" placeholder="Address" name="address1" id="address1" [(ngModel)]="company.address1"> 
			                    </div>
	                    	</div>
	                    	<div class="col-md-2 col-sm-4 col-xs-12">
		                    	<div class="form-group">
			                        <label>&nbsp;</label>
			                        <input type="text" class="form-control" placeholder="Apartment, suite, unit etc. (optional)" name="address2" id="address2" [(ngModel)]="company.address2"> 
			                    </div>
	                    	</div>
	                    	<div class="col-md-2 col-sm-4 col-xs-12">
                                <div class="form-group">
                                    <label>City <span class="font-red" *ngIf="!isValidCity" >(*)</span></label>
                                    <input type="text" class="form-control" placeholder="City" name="city" id="city" [(ngModel)]="company.city"> 
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-4 col-xs-12">
                                <div class="form-group">
                                    <label>State <span class="font-red" *ngIf="!isValidState" >(*)</span></label>
                                    <ng-select [(ngModel)]="company['state']" (change)="getState($event)" >
                                        <ng-option *ngFor="let st of states" [value]="st.code" >{{st.name}}</ng-option>
                                    </ng-select>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-4 col-xs-12">
                                <div class="form-group">
                                    <label class="control-label">Postcode / Zip <span class="font-red" *ngIf="!isValidZip" >(*)</span></label>
                                    <input type="text" class="form-control c-square c-theme" placeholder="Postcode / Zip" name="zipcode" id="zipcode" [(ngModel)]="company.zipcode"> 
                                </div>
                            </div>

                            <div class="col-md-4 col-xs-12">
                                <div class="form-group">
                                    <label class="control-label">Phone <span class="font-red" *ngIf="!isValidPhone" >(*)</span></label>
                                    <input type="text" class="form-control c-square c-theme" placeholder="Phone" name="phone" id="phone" [(ngModel)]="company.phone"> 
                                </div>
                            </div>
                            <div class="col-md-4 col-xs-12">
                                <div class="form-group">
                                    <label class="control-label">Phone 2</label>
                                    <input type="text" class="form-control c-square c-theme" placeholder="Phone 2 (optional)" name="phone2" id="phone2" [(ngModel)]="company.phone2"> 
                                </div>
                            </div>
                            <div class="col-md-4 col-xs-12">
                                <div class="form-group">
                                    <label class="control-label">Fax </label>
                                    <input type="text" class="form-control c-square c-theme" placeholder="Fax" name="fax" id="fax" [(ngModel)]="company.fax"> 
                                </div>
                            </div>

                            <div class="col-md-4 col-xs-12">
                                <div class="form-group">
                                    <label class="control-label">Email <span class="font-red" *ngIf="!isValidEmail" >(*)</span></label>
                                    <input type="text" class="form-control c-square c-theme" placeholder="Email" name="email" id="email" [(ngModel)]="company.email"> 
                                </div>
                            </div>
                            <div class="col-md-4 col-xs-12">
                                <div class="form-group">
                                    <label class="control-label">Email 2</label>
                                    <input type="text" class="form-control c-square c-theme" placeholder="Email 2 (optional)" name="email2" id="email2" [(ngModel)]="company.email2"> 
                                </div>
                            </div>
                            <div class="col-md-4 col-xs-12">
                                <div class="form-group">
                                    <label class="control-label">Website <span class="font-red" *ngIf="!isValidWebsite" >(*)</span></label>
                                    <input type="text" class="form-control c-square c-theme" placeholder="Website" name="website" id="website" [(ngModel)]="company.website"> 
                                </div>
                            </div>

                            <div class="col-md-3 col-xs-12">
                                <div class="form-group">
                                    <label class="control-label">Facebook Url</label>
                                    <input type="text" class="form-control c-square c-theme" placeholder="Facebook" name="facebook" id="facebook" [(ngModel)]="company.facebook"> 
                                </div>
                            </div>
                            <div class="col-md-3 col-xs-12">
                                <div class="form-group">
                                    <label class="control-label">Instagram Url</label>
                                    <input type="text" class="form-control c-square c-theme" placeholder="Instagram" name="instagram" id="instagram" [(ngModel)]="company.instagram"> 
                                </div>
                            </div>
                            <div class="col-md-3 col-xs-12">
                                <div class="form-group">
                                    <label class="control-label">Twitter Url</label>
                                    <input type="text" class="form-control c-square c-theme" placeholder="Twitter" name="twitter" id="twitter" [(ngModel)]="company.twitter"> 
                                </div>
                            </div>
                            <div class="col-md-3 col-xs-12">
                                <div class="form-group">
                                    <label class="control-label">Skype Url</label>
                                    <input type="text" class="form-control c-square c-theme" placeholder="Skype" name="skype" id="skype" [(ngModel)]="company.skype"> 
                                </div>
                            </div>

                            <div class="col-md-6 col-xs-12">
                                <div class="form-group">
                                    <label class="control-label">Business Days</label>
                                    <input type="text" class="form-control c-square c-theme" placeholder="How many days per week?" name="businessdays" id="businessdays" [(ngModel)]="company.businessdays"> 
                                </div>
                            </div>
                             <div class="col-md-6 col-xs-12">
                                <div class="form-group">
                                    <label class="control-label">Business Hours</label>
                                    <input type="text" class="form-control c-square c-theme" placeholder="Business Hours (From/ To)" name="businesshours" id="businesshours" [(ngModel)]="company.businesshours"> 
                                </div>
                            </div>
                        </div>
                        <div class="row d-none hide">
                            <!-- LOGO -->
                            <div class="col-md-2 col-sm-6 col-xs-12">
                                <div class="form-group">
                                    <div for="logo" style="margin-right: 5px;">Logo (Ex: 120px x 120px)<span class="font-red" *ngIf="!isValidLogo" >(*)</span></div>
                                    <button type="button" class="btn blue" id="logo" (click)="openMedia('listMedias', 'logoImg')">Open Media Library</button>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-xs-12 text-center">
                                <div class="c-display-inline-block">
                                    <img class="img-responsive" style="max-width: 150px;" id='base64logo' [src]="uploadUrl + company['logo']" *ngIf="company['logo']"/>
                                </div>
                            </div>
                        
                            <!-- LOGO BW-->
                            <div class="col-md-2 col-sm-6 col-xs-12">
                                <div class="form-group">
                                    <div for="logobw" style="margin-right: 5px;">Logo (B&W)<span class="font-red" *ngIf="!isValidLogoBW" >(*)</span></div>
                                    <button type="button" class="btn blue" id="logobw" (click)="openMedia('listMedias','logoBWImg')">Open Media Library</button>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-xs-12 text-center">
                                <div class="mt-widget-1 logo-bw c-display-inline-block">
                                    <img class="img-responsive" style="max-width: 150px;" id='base64logobw' [src]="uploadUrl + company['logobw']" *ngIf="company['logobw']"/>
                                </div>
                            </div>
                        
                            <!-- LOGO MOBILE-->
                            <div class="col-md-2 col-sm-6 col-xs-12">
                                <div class="form-group">
                                    <div for="logomob" style="margin-right: 5px;">Logo Mobile<span class="font-red" *ngIf="!isValidLogoMob" >(*)</span></div>
                                    <button type="button" class="btn blue" id="logomob" (click)="openMedia('listMedias','logoMobImg')">Open Media Library</button>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-xs-12 text-center">
                                <div class="c-display-inline-block">
                                    <img class="img-responsive" style="max-width: 150px;" id='base64logom' [src]="uploadUrl + company['logomob']" *ngIf="company['logomob']"/>
                                </div>
                            </div>
                       
                            <!-- IMAGES -->
                            <div class="col-md-12 col-xs-12">
                                <div class="form-group">
                                    <div for="images" style="margin-right: 5px;">Images</div>
                                    <button type="button" class="btn blue" id="images" (click)="openMedia('listMedias', 'null')">Open Media Library</button>
                                </div>
                            </div>
                            <div class="col-md-12 col-xs-12 right" *ngIf="company['images']">
                                <div class="row">
                                    <div class="col-md-2 col-sm-4 col-xs-12 text-center" *ngFor="let img of company['images']">
                                        <div class="c-display-inline-block">
                                            <img class="img-responsive" style="max-width: 150px;" id='base64image' [src]="uploadUrl + img"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
			            </div>
                    </div>
                    <div class="form-actions">
                        <button type="button" class="btn blue" (click)="onSubmit()">Update</button>
                        <button type="reset" class="btn default" (click)="reset()">Cancel</button>
                    </div>
                <!-- </form> -->
            </div>
        </div>
	</div>
    <!-- END: ADD/EDIT -->
</div>

<!-- LIST MEDIA -->
<div class="modal fade hide" id="listMedias" tabindex="-1" role="basic" aria-hidden="true" style="padding-right: 17px;" [ngStyle]="{'display': isModal}" > <!-- [ngClass]="{'hide': modalId != 'listMedia'}" -->
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <div class="pull-right">
                    <label class="c-margin-l-r-5" data-toggle="buttons">
                        <select class="form-control category-select" (change)="categorySelected($event)" [(ngModel)]="selectedCatgory">
                            <option value="{{c['id']}}" *ngFor="let c of categories">{{c['name']}}</option>
                        </select>
                    </label>
                    <a class="btn dark btn-outline pull-right" href="javascript:;" (click)="closeModal()"> 
                        <i class="fa fa-times"></i>
                    </a>
                </div>
                <h4 class="modal-title">Library Media</h4>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-3 col-sm-6 col-xs-12 c-margin-b-15" *ngFor="let media of medias; let i = index" [attr.data-index]="i">
                        <div class="mt-widget-1 bg-default" [ngClass]="{'logo-bw' : imgType == 'logobw'}">
                            <div class="mt-icon" *ngIf="isLogo">
                                <label class="mt-radio mt-radio-outline">
                                    <input type="radio" name="listlogo" id="logo-{{media['id']}}" [value]="media['id']" (change)="selectedLogo(media['id'], imgType)">
                                    <span></span>
                                </label>
                            </div>
                            <div class="mt-icon" *ngIf="!isLogo">
                                <label class="mt-checkbox mt-checkbox-outline">
                                    <input type="checkbox" name="listmedia" id="media-{{media['id']}}" [value]="media['id']" (change)="selectedFilesImg(media['name'])" [checked]="selectedImage(media['name'])"> 
                                    <span></span>
                                </label>
                            </div>
                            <div class="mt-img">
                                <img [src]="uploadUrl + (media['name'])" class="img-responsive" (click)="imageClick(media['name'], imgType)" > 
                            </div>
                            <div class="mt-body">
                                <h3 class="mt-username hide">{{media.name}}</h3>
                                <div class="mt-stats hide">
                                    <div class="btn-group btn-group btn-group-justified">
                                        <a (click)="viewMedia(media.id, 'view')" class="btn font-blue">
                                            <i class="icon-magnifier"></i> View 
                                        </a>
                                        <a (click)="deleteMedia(media.id, 'delete')" class="btn font-red">
                                            <i class="icon-trash"></i> Delete 
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 col-xs-12 text-right c-padding-b-20">
                        <ul *ngIf="pager.pages && pager.pages.length" class="pagination bootpag">
                            <li [ngClass]="{disabled:pager.currentPage === 1}">
                                <a (click)="setPageMedia(1)"><i class="fa fa-angle-double-left"></i></a>
                            </li>
                            <li [ngClass]="{disabled:pager.currentPage === 1}">
                                <a (click)="setPageMedia(pager.currentPage - 1)"><i class="fa fa-angle-left"></i></a>
                            </li>
                            <li *ngFor="let page of pager.pages" [ngClass]="{'c-active': pager.currentPage === page}">
                                <a (click)="setPageMedia(page)">{{page}}</a>
                            </li>
                            <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                                <a (click)="setPageMedia(pager.currentPage + 1)"><i class="fa fa-angle-right"></i></a>
                            </li>
                            <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                                <a (click)="setPageMedia(pager.totalPages)"><i class="fa fa-angle-double-right"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn dark btn-outline" (click)="closeModal()">
                    <span> Close</span>
                </button>
            </div>
        </div>
    </div>
</div>


<!-- ADD MEDIA -->
<div class="modal fade hide" id="addMedia" tabindex="-1" role="basic" aria-hidden="true" style="padding-right: 17px;" [ngStyle]="{'display': isModal}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <div class="pull-right">
                    <div class="pull-left">
                        <span class="btn green fileinput-button">
                            <i class="fa fa-plus"></i>
                            <span> Add files... </span>
                            <input accept="image/*" id="file-upload" multiple (change)="onUpload($event)" type="file" #mediaFileInput /> 
                        </span>
                    </div>
                    <label class="c-margin-l-r-5" data-toggle="buttons">
                        <select class="form-control category-select" (change)="categorySelected($event)" [(ngModel)]="selectedCatgory">
                            <option value="{{c['id']}}" *ngFor="let c of categories">{{c['name']}}</option>
                        </select>
                        <span class="font-red" style="float: left; display: inline-block;" *ngIf="requireMessage != ''" >(*)</span>
                    </label>
                    <a class="btn dark btn-outline pull-right" href="javascript:;" (click)="closeModal()"> 
                        <i class="fa fa-times"></i>
                    </a>
                </div>
                <h4 class="modal-title">Add Media</h4>
            </div>
            <div class="modal-body" *ngIf="filesUpload.length > 0">
                <div class="row">
                    <div class="col-xs-12 font-red text-center c-padding-b-20" *ngIf="requireMessage != ''">
                        {{requireMessage}}
                    </div>
                    <div class="col-md-12 col-xs-12">
                        <table role="presentation" class="table table-striped clearfix">
                            <thead>
                                <tr>
                                    <th style="max-width: 80px;" class="col-md-1 col-xs-2"></th>
                                    <th class="col-md-6 col-xs-4">Name</th>
                                    <th class="col-md-2 hidden-xs">Size</th>
                                    <th class="col-md-2 col-xs-4">Progress</th>
                                    <th class="col-md-1 col-xs-2"></th>
                                </tr>
                            </thead>
                            <tbody class="files"> 
                                <tr class="template-upload fade in" *ngFor="let file of filesUpload; let i = index">
                                    <td>
                                        <span class="preview" *ngIf="modalId == 'addMedia'">
                                            <img class="img-responsive" style="max-height: 60px;" id='base64image' [src]="file.imgCode"/>
                                        </span>
                                    </td>
                                    <td>
                                        <p class="name">{{file.name}}</p>
                                        <strong class="error text-danger label label-danger"></strong>
                                    </td>
                                    <td>
                                        <p class="size">{{convertSize(file.size)}}</p>
                                    </td>
                                    <td>
                                        <div class="progress progress-striped active" role="progressbar" aria-valuemin="0" aria-valuemax="100" aria-valuenow="0">
                                            <div class="progress-bar progress-bar-success" style="width:0%;"></div>
                                        </div>
                                    </td>
                                    <td> 
                                        <button class="btn blue start hide">
                                            <i class="fa fa-upload"></i>
                                            <span>Start</span>
                                        </button>  
                                        <button class="btn red cancel" (click)="removeFile(file['name'])" >
                                            <i class="fa fa-trash"></i>
                                        </button>  
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn blue start" [disabled]="!isLoaded" (click)="uploadAll()">
                    <i class="fa fa-upload"></i>
                    <span> Start upload </span>
                </button>
                <button type="reset" class="btn warning cancel" [disabled]="!isLoaded" (click)="closeModal()">
                    <i class="fa fa-ban-circle"></i>
                    <span> Cancel upload </span>
                </button>
                <button type="button" class="btn red delete" [disabled]="!isLoaded" (click)="removeAllFiles()">
                    <i class="fa fa-trash"></i>
                    <span> Delete All</span>
                </button>
            </div>
        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>

<!-- BEGIN ADD CATEGORY -->
<div class="modal fade hide" id="addCategoryModal" tabindex="-1" role="basic" aria-hidden="true" style="padding-right: 17px;" [ngStyle]="{'display': isModal}" >
    <div class="modal-dialog modal-md">
        <div class="modal-content">
            <div class="modal-header">
                <a class="btn dark btn-outline pull-right" href="javascript:;" (click)="closeModal()"> 
                    <i class="fa fa-times"></i>
                </a>
                <h4 class="modal-title">Add Category</h4>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12 col-xs-12">
                        <div class="form-group">
                            <label>Name <span class="font-red" *ngIf="!isValidCategoryName" >(*)</span></label>
                            <input type="text" class="form-control" placeholder="Category" name="categoryName" id="categoryName" > <!-- [(ngModel)]="category.name" -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn blue" (click)="onSubmitCategory()">Add</button>
                <button type="button" class="btn dark btn-outline" (click)="closeModal()">
                    <span> Close</span>
                </button>
            </div>
        </div>
    </div>
</div>
<!-- END ADD CATEGORY -->