import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-brochure',
  templateUrl: './brochure.component.html',
  styleUrls: ['./brochure.component.scss']
})
export class BrochureComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
