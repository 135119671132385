import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule  } from '@angular/http';
import { RouterModule, Routes} from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { Ng2PageScrollModule } from 'ng2-page-scroll';
import { NgSelectModule } from '@ng-select/ng-select';

import { LocalData, LibsService } from './_services';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { environment } from '../environments/environment';

// AngularFire2 Modules`
import { AngularFireModule } from 'angularfire2';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { AngularFireStorageModule } from 'angularfire2/storage';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { AngularFireFunctionsModule } from 'angularfire2/functions';

import { AppLayoutComponent } from './_layout/app-layout/app-layout.component';
import { SiteLayoutComponent } from './_layout/site-layout/site-layout.component';

import { LoginComponent } from './account/login/login.component';
import { RegisterComponent } from './account/register/register.component';
import { ForgotPasswordComponent } from './account/forgot-password/forgot-password.component';
import { VerifyEmailComponent } from './account/verify-email/verify-email.component';
import { ProfileComponent } from './account/profile/profile.component';

import { DashboardComponent } from './dashboard/dashboard.component';
import { UsersComponent } from './users/users.component';
import { MenuComponent } from './menu/menu.component';
import { RolesComponent } from './roles/roles.component';
import { TypeComponent } from './filter/type/type.component';
import { MediaComponent } from './media/media.component';
import { VideoComponent } from './media/video/video.component';
import { BrochureComponent } from './media/brochure/brochure.component';
import { CompanyComponent } from './company/company.component';

import { ChurchesComponent } from './churches/churches.component';
import { MassesComponent } from './masses/masses.component';
import { VideoTypeComponent } from './general/video-type/video-type.component';

const appRoutes: Routes = [
  {
    path: '', 
    component: AppLayoutComponent,
    children: [
      { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
      { path: 'dashboard', component: DashboardComponent },
      { path: 'roles', component: RolesComponent },
      { path: 'menu', component: MenuComponent },
      // Site Info
      { path: 'company', component: CompanyComponent },
      // Account
      { path: 'profile', component: ProfileComponent },
      { path: 'users', component: UsersComponent },
      // Media
      { path: 'media', component: MediaComponent },
      { path: 'video', component: VideoComponent },
      { path: 'brochure', component: BrochureComponent },
      // Filter
      { path: 'type', component: TypeComponent },
      // General
      { path: 'videotype', component: VideoTypeComponent },
      // Project page
      { path: 'churches', component: ChurchesComponent },
      { path: 'masses', component: MassesComponent },
    ]
  },
  {
    path: '', 
    component: SiteLayoutComponent,
    children: [
          // { path: '', redirectTo: '/login', pathMatch: 'full'},
          { path: 'login', component: LoginComponent },
          { path: 'registers', component: RegisterComponent },
          { path: 'forgot-password', component: ForgotPasswordComponent }
        ]
  },
  { path: '**', redirectTo: '/dashboard', pathMatch: 'full' },
]

@NgModule({
  declarations: [
    AppComponent,
    AppLayoutComponent,
    SiteLayoutComponent,
    LoginComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    VerifyEmailComponent,
    ProfileComponent,
    DashboardComponent,
    UsersComponent,
    MenuComponent,
    RolesComponent,
    TypeComponent,
    MediaComponent,
    VideoComponent,
    BrochureComponent,
    CompanyComponent,
    ChurchesComponent,
    MassesComponent,
    VideoTypeComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    AngularEditorModule,
    Ng2PageScrollModule,
    HttpModule,
    HttpClientModule,
    RouterModule.forRoot (appRoutes, { useHash: true }),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFireFunctionsModule,
    AngularFireDatabaseModule,
    NgSelectModule,
  ],
  providers: [
  	LocalData
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
