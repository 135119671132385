<div class="row">
    <!-- BEGIN: ADD/EDIT -->
	<div class="col-md-6 col-sm-6 col-xs-12">
		<div class="portlet light bordered">
			<div class="portlet-title">
	            <div class="caption">
	                <i class="icon-microphone font-dark hide"></i>
	                <span class="caption-subject bold font-dark uppercase">{{title}} Type</span>
	            </div>
	        </div>
            <div class="portlet-body form">
                <form role="form" [formGroup]="form" (ngSubmit)="onSubmit()">
                    <div class="form-body">
	                    <div class="row">
	                    	<div class="col-md-12 col-xs-12">
		                    	<div class="form-group">
			                        <label>Name <span class="font-red" *ngIf="!isValidName" >(*)</span></label>
			                        <input type="text" class="form-control" placeholder="Type Name" name="name" id="name" required formControlName="name"  minlength="4" [(ngModel)]="type.name"> 
			                    </div>
	                    	</div>
                            <div class="col-md-12 col-xs-12">
                                <div class="form-group">
                                    <label>Short Code <span class="font-red" *ngIf="!isValidCode" >(*)</span></label>
                                    <input type="text" class="form-control" placeholder="Type Short Code" name="code" id="code" required formControlName="code"  minlength="4" [(ngModel)]="type.code"> 
                                </div>
                            </div>
			            </div>
                    </div>
                    <div class="form-actions">
                        <button type="submit" class="btn blue">{{btnText}}</button>
                        <button type="reset" class="btn default" (click)="reset()">Cancel</button>
                    </div>
                </form>
            </div>
        </div>
	</div>
    <!-- END: ADD/EDIT -->

    <!-- BEGIN: LIST -->
	<div class="col-md-6 col-sm-6 col-xs-12">
        <div class="portlet light portlet-fit bordered show-all-list">
            <div class="portlet-title">
                <div class="caption">
                    <i class="icon-microphone font-dark hide"></i>
                    <span class="caption-subject bold font-dark uppercase">Types</span>
                </div>
            </div>
            <div class="portlet-body">
                <div class="table-scrollable">
                    <table class="table table-striped table-bordered table-advance table-hover">
                        <thead>
                            <tr>
                                <th class="col-md-6 col-xs-5"> Name </th>
                                <th class="col-md-3 col-xs-3"> Code </th>
                                <th class="col-md-3 col-xs-4"> Action </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let p of types ">
                                <td>
                                    {{p['name']}}
                                </td>
                                <td>{{p['code']}}</td>
                                <td>
                                    <a (click)="typeEdit(p['id'])" class="btn dark btn-sm btn-outline sbold uppercase">
                                        <i class="fa fa-pencil"></i> 
                                    </a>
                                    <a (click)="typeDelete(p['id'], 'delete')" class="btn red btn-sm btn-outline sbold uppercase">
                                        <i class="fa fa-trash"></i> 
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <!-- END: LIST -->
</div>


<div class="modal fade hide" id="delete" tabindex="-1" role="basic" aria-hidden="true" style="padding-right: 17px;" [ngStyle]="{'display':isModal}">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="closeModal()"></button>
                <h4 class="modal-title">Confirmation</h4>
            </div>
            <div class="modal-body"> Are you sure you want to delete type: '{{type['name']}}' ? </div>
            <div class="modal-footer">
                <button type="button" class="btn red" (click)="confirmYes()" > Yes </button>
                <button type="button" class="btn dark btn-outline" data-dismiss="modal" (click)="closeModal()">No</button>
            </div>
        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>