import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl } from "@angular/forms";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { VideoTypeModel, VideoTypeService, LibsService } from '../../_services';

@Component({
  selector: 'app-video-type',
  templateUrl: './video-type.component.html',
  styleUrls: ['./video-type.component.scss']
})
export class VideoTypeComponent implements OnInit {

  videotypes = [];
  videotype: VideoTypeModel = new VideoTypeModel();
  form: FormGroup;
  isValidName = true;
  isValidURL = true;
  submitted = false;
  isModal = 'none';
  modalId = '';
  key = '';
  btnText = 'Submit';
  title = 'Add';

  constructor(
  	private videotypeService: VideoTypeService,
  	private fb: FormBuilder,
    private _libs: LibsService,
  ) { }

  ngOnInit() {
  	this.createForm();
  	this.getTypesList();
  }

  getTypesList(){
  	this.videotypeService.getVideoTypesList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ id: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(videotypes => {
      this.videotypes = videotypes.sort(this._libs.dynamicSortByName('name'));
      // console.log(this.videotypes);
    });
  }

  getType(id){
    this.key = id;
    var temp = this.videotypes.filter(pt => pt['id'] == id);
    return this.videotype = temp[0];
  }

  createForm() {
    this.form = this.fb.group({
      name: new FormControl('', Validators.required),
      url: new FormControl(''),
      param1: new FormControl(''),
    });
  }

  onSubmit() {
    let name = this.form.controls['name'].value;
    let url = this.form.controls['url'].value;
  	
  	if(name == '' || name == null){
  		this.isValidName = false
  		return;
  	}else{
  		this.isValidName = true;
  	}
    if(url == '' || url == null){
      this.isValidURL = false
      return;
    }else{
      this.isValidURL = true;
    }
    this.submitted = true;

    this.videotype = this.form.value;
    if (this.key){
      this.update(this.videotype);
    }else{
      this.save(this.videotype);
    }
  }

  update(f){
    this.videotypeService.updateVideoType(this.key, f);
    this.reset();
  }

  save(f) {
    this.videotypeService.createVideoType(f);
    this.reset();
  }

  reset(){
	this.title = 'Add';
	this.btnText = 'Submit';
    this.videotype = new VideoTypeModel();
    this.form.reset();
    this.key = '';
  }

  videotypeEdit(id){
    this.btnText = 'Update';
    this.title = 'Edit';
    this.videotype = this.getType(id);
  }

  videotypeDelete(id, modalId){
    this.modalId = modalId;
    this.videotype = this.getType(id);
    this.isModal = this._libs.openModal(modalId);
  }

  confirmYes(){
    // console.log(this.videotype['id']);
    this.videotypeService.deleteVideoType(this.videotype['id']);
    this.closeModal();
  }

  closeModal(){
    this.modalId = '';
    this.videotype = new VideoTypeModel();
    this.isModal = this._libs.closeModal();
  }

}
