import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl } from "@angular/forms";
import { Http, Response, Headers } from '@angular/http';
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';
import * as $ from 'jquery';

import { LocalData, AuthenticationService, UserModel, LibsService, 
          MenuService, RoleService,
        } from '../../_services';

@Component({
  selector: 'app-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss']
})
export class AppLayoutComponent implements OnInit {
  currentUser: UserModel = new UserModel();
  initialName = '';
  initialNameBG = {};
  today = new Date();

  uploadUrl = this._libs.getURL()+'/assets/users/';

  website = this._libs.getURL();
  siteName = this._localDB.sitename;
  wLogo = this._localDB.wlogo;
  logo = this._localDB.logo;

  menus = [];
  allSubmenus = [];
  submenus = [];
  submenusL2 = [];

  roles = [];
  permission = [];
  userPermission = [];

  isLogged = false;
  
  constructor(
  	private _libs: LibsService,
    private _localDB: LocalData,
    private menuService: MenuService,
    private roleService: RoleService,
    private authService: AuthenticationService,
  ) {
    $.getScript('../../../assets/global/scripts/app.min.js');
    $.getScript('../../../assets/pages/scripts/dashboard.min.js');
    $.getScript('../../../assets/layout/js/layout.min.js');
    this.currentUser = this._libs.getLocalStorageItem('currentUser');
    this.menus = this._libs.getLocalStorageItem('menus');
    this.allSubmenus = this._libs.getLocalStorageItem('allSubmenus');
    this.roles = this._libs.getLocalStorageItem('roles');

    // console.log(this.currentUser['id']);
    if (this.currentUser['id'] == undefined || this.currentUser['id'] == ''){
      this.isLogged = false;
      this._libs.go2Page('login');
    }else{
      $.getScript('../../../assets/layout/js/regular-page.js');
      this.isLogged = true;
      this.initialName = this.currentUser['firstname'].substr(0,1) + this.currentUser['lastname'].substr(0,1);
      this.initialNameBG = this._libs.getInitialBG((this.currentUser['firstname'] + this.currentUser['lastname']).length);
      this._libs.loading();
      if (this.roles.length == 0){
        this.getRolesList();
      }else{
        this.step2();
      }
    }

  }

  ngOnInit(): void {
  }

  step2(){
    if (this.menus.length == 0){
      this.getMenusList();
    }else{
      this.step3();
    }
    
  }

  step3(){
    this._libs.unLoading();

    this.getuserMenu();  
    if (this.allSubmenus.length == 0){
      this.getSubmenusList();
    }else{
      this.submenus = this.allSubmenus.filter(subl2 => subl2['isSubmenuL2'] == false);
      this.submenusL2 = this.allSubmenus.filter(subl2 => subl2['isSubmenuL2'] == true);
    }
    // if (this.categories.length == 0){
    //   this.getCategoriesList();
    // }else{
    //   this.getUserCategory();
    // }
  }

  getuserMenu(){
    if (this.currentUser['roleId'] != ''){
      var temp = this.getRole(this.currentUser['roleId']);
      this.permission = temp['permission'];
      if (this.menus.length > 0 && this.permission.length > 0){
        // console.log(this.permission);
        // console.log(this.menus);
        // console.log(this.roles);
        this.userPermission = this._libs.similarArr(this.menus, this.permission);
      }
    }
  }

  logout() {
    // sessionStorage.removeItem('currentUser');
    sessionStorage.clear();
    this.authService.doSignOut();
    this._libs.go2Page('login');
  }

  ///MENU
  getMenusList(){
    this.menuService.getMenusList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ id: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(menus => {
      this.menus = menus;
      this.menus = this.menus.sort(this._libs.dynamicSortByName('order'));
      this._libs.setLocalStorageItem('menus', menus);
      // console.log(this.menus);
      this.step3();
    });
  }

  getSubmenusList(){
    this.menuService.getSubmenusList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ id: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(allSubmenus => {
      this.allSubmenus = allSubmenus;
      this.submenus = this.allSubmenus.filter(subl2 => subl2['isSubmenuL2'] == false);
      this.submenusL2 = this.allSubmenus.filter(subl2 => subl2['isSubmenuL2'] == true);
      // this._libs.setLocalStorageItem('allSubmenus', this.allSubmenus);
      // console.log(this.submenus);
    });
  }

  getSubMenusById(id){
    // console.log(id);
    var subMenu = this.submenus.filter(submenu => submenu['menuId'] == id);
    return subMenu.sort(this._libs.dynamicSortByName('order'));
  }

  getSubMenusL2ById(id){
    // console.log(id);
    return this.submenusL2.filter(submenu => submenu['menuId'] == id);
  }

  checkIsSubmenuL2(id){
    let items =  this.submenus.filter(submenu => submenu['menuId'] == id);
    console.log(items);
    if (items.length > 0){
      return true;
    }else{
      return false;
    }
  }

  ///ROLE
  getRolesList(){
    this.roleService.getRolesList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ id: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(roles => {
      this.roles = roles;
      // this._libs.setLocalStorageItem('roles', roles);
      this._libs.unLoading();
      this.step2();
      // console.log(this.menus);
    });
  }

  getRole(id){
    return (this.roles.filter(role => role['id'] == id))[0];
  }

}
