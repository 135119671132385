<div class="row">
    
    <!-- BEGIN: LIST -->
	<div class="col-md-12 col-sm-12 col-xs-12">
        <div class="portlet light portlet-fit bordered all-users">
            <div class="portlet-title">
                <div class="caption">
                    <i class="icon-microphone font-dark hide"></i>
                    <span class="caption-subject bold font-dark uppercase">Users Management</span>
                </div>
                <div class="actions">
                    <div class="btn-group">
                        <label class="btn blue btn-outline btn-sm">
                            <span class="" (click)="newUser('userModal')" > Add User </span>
                        </label>
                    </div>
                </div>
            </div>
            <div class="portlet-body" *ngIf="isLoaded">
                <div class="row">
                    <div class="col-md-12 font-red">{{this.result}}</div>
                </div>
                <div class="table-scrollable">
                    <table class="table table-striped table-bordered table-advance table-hover">
                        <thead>
                            <tr>
                                <th class="col-md-2 col-xs-2"> Name </th>
                                <th class="col-md-2 col-xs-2"> Email </th>
                                <th class="col-md-2 col-xs-2"> Phone</th>
                                <th class="col-md-2 col-xs-2"> Occupation </th>
                                <th class="col-md-1 col-xs-1"> Role </th>
                                <th class="col-md-1 col-xs-1"> Active </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let c of users ">
                                <td class="hidden-xs">{{c['firstname']}} {{c['lastname']}}</td>
                                <td>{{c['email']}}</td>
                                <td>
                                    <p>O: {{c['phone']}} <span *ngIf="c['ext']"> - {{c['ext']}}</span></p>
                                    <p>C: {{c['cellphone']}}</p>
                                </td>
                                <td>{{c['occupation']}}</td>
                                <td>{{c['roleName']}}</td>
                                <td>
                                    <label class="mt-checkbox mt-checkbox-outline">
                                        <input type="checkbox" name="user-item-{{c['active']}}" id="{{c['id']}}" class="user-item {{c['active']}} user-{{c['id']}}" [value]="c['id']" (change)="activeUser(c['id'])" [checked]="(c['active'])">
                                        <span></span>
                                    </label>
                                </td>
                                <td>
                                    <a (click)="userEdit(c['id'], 'userModal')" class="btn dark btn-sm btn-outline sbold uppercase">
                                        <i class="fa fa-pencil"></i> Edit 
                                    </a>
                                    <a (click)="userDelete(c['id'], 'deleteModal')" class="btn red btn-sm btn-outline sbold uppercase">
                                        <i class="fa fa-trash"></i> Delete 
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <!-- END: LIST -->
</div>


<div class="modal fade hide" id="deleteModal" tabindex="-1" role="basic" aria-hidden="true" style="padding-right: 17px;" [ngStyle]="{'display':isModal}">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="closeModal()"></button>
                <h4 class="modal-title">Confirmation</h4>
            </div>
            <div class="modal-body"> Are you sure you want to delete User: '{{user['firstname']}} {{user['lastname']}}' ? </div>
            <div class="modal-footer">
                <button type="button" class="btn red" (click)="confirmYes()" > Yes </button>
                <button type="button" class="btn dark btn-outline" data-dismiss="modal" (click)="closeModal()">No</button>
            </div>
        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>


<!-- BEGIN ADD USER -->
<div class="modal fade hide" id="userModal" tabindex="-1" role="basic" aria-hidden="true" style="padding-right: 17px;" [ngStyle]="{'display': isModal}"> <!-- [ngClass]="{'hide': modalId != 'listMedia'}" -->
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <a class="btn dark btn-outline pull-right" href="javascript:;" (click)="closeModal()"> 
                    <i class="fa fa-times"></i>
                </a>
                <h4 class="modal-title">{{title}} User</h4>
            </div>
            <div class="modal-body">
                <div class="col-md-12 font-red text-center c-padding-b-15" *ngIf="alertMessage != ''">{{alertMessage}}</div>
                <div class="row">
                    <div class="col-md-5 col-xs-12">
                        <div class="form-group">
                            <label>First Name <span class="font-red" *ngIf="!isValidFirstName" >(*)</span></label>
                            <input type="text" class="form-control" placeholder="First Name" name="firstname" id="firstname" [(ngModel)]="user.firstname"> 
                        </div>
                    </div>
                    <div class="col-md-5 col-xs-12">
                        <div class="form-group">
                            <label>Last Name <span class="font-red" *ngIf="!isValidLastName" >(*)</span></label>
                            <input type="text" class="form-control" placeholder="Last Name" name="lastname" id="lastname" [(ngModel)]="user.lastname"> 
                        </div>
                    </div>
                    <div class="col-md-2 col-xs-12">
                        <div class="form-group">
                            <label>Active</label>
                            <div class="mt-checkbox-list">
                                <label class="mt-checkbox mt-checkbox-outline">
                                    <input type="checkbox" id="active" name="active" class="c-check" [(ngModel)]="user.active">
                                    <span></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-xs-12">
                        <div class="form-group">
                            <label>Email <span class="font-red" *ngIf="!isValidEmail" >(*)</span></label>
                            <input type="text" class="form-control" placeholder="Email" name="email" id="email" [disabled]="key != ''" [(ngModel)]="user.email"> 
                        </div>
                    </div>
                    <div class="col-md-6 col-xs-12">
                        <div class="form-group">
                            <label>Password <span class="font-red" *ngIf="!isValidPassword" >(*)</span></label>
                            <input type="password" class="form-control" placeholder="Password" name="password" id="password" [(ngModel)]="temporaryPassword"> 
                        </div>
                    </div>
                    <div class="col-md-4 col-xs-8">
                        <div class="form-group">
                            <label>Phone <span class="font-red" *ngIf="!isValidPhone" >(*)</span></label>
                            <input type="text" class="form-control" placeholder="Phone" name="phone" id="phone" [(ngModel)]="user.phone"> 
                        </div>
                    </div>
                    <div class="col-md-2 col-xs-4">
                        <div class="form-group">
                            <label>Extention </label>
                            <input type="text" class="form-control" placeholder="Extention" name="ext" id="ext" [(ngModel)]="user.ext"> 
                        </div>
                    </div>
                    <div class="col-md-6 col-xs-6">
                        <div class="form-group">
                            <label>Cell Phone </label>
                            <input type="text" class="form-control" placeholder="Cell Phone" name="cellphone" id="cellphone" [(ngModel)]="user.cellphone"> 
                        </div>
                    </div>
                    <div class="col-md-6 col-xs-12">
                        <div class="form-group">
                            <label class="control-label">Occupation <span class="font-red" *ngIf="!isValidOccupation" >(*)</span></label>
                            <input type="text" placeholder="Occupation" class="form-control" name="occupation" id="occupation" [(ngModel)]="user.occupation" /> 
                        </div>
                    </div>
                    <div class="col-md-6 col-xs-12">
                        <div class="form-group">
                            <label>Role<span class="font-red" *ngIf="!isValidRole" >(*)</span></label>
                            <ng-select [(ngModel)]="user['roleId']" name="roleId" id="roleId" (change)="roleSelected($event)">
                                <option value="">Select Role</option>
                                <ng-option *ngFor="let role of roles" [value]="role.id" >{{role.name}}</ng-option>
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-md-12 col-xs-12">
                        <div class="form-group">
                            <label class="control-label">About</label>
                            <textarea class="form-control" rows="3" placeholder="About" name="about" id="about" [(ngModel)]="user.about"></textarea>
                        </div>
                    </div>
                    <div class="col-md-12 col-xs-12 hide d-none">
                        <div class="form-group">
                            <label class="control-label col-xs-2">Avatar</label>
                            <div class=" col-xs-4">
                                <span class="btn green fileinput-button">
                                    <i class="fa fa-plus"></i>
                                    <span> Add files... </span>
                                    <input accept="image/*" id="file-upload" (change)="onUpload($event)" type="file" #fileInput /> 
                                </span>
                            </div>
                            <div class="col-xs-6">
                                 <span class="preview" *ngIf="!isUpload &&  user.avatar != ''">
                                    <img class="img-responsive" style="max-height: 60px;" id='base64image' [src]="uploadUrl + user.avatar" />
                                </span>
                                 <span class="preview" *ngIf="isUpload">
                                    <img class="img-responsive" style="max-height: 60px;" id='base64image' [src]="fileUpload.imgCode" />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn blue" (click)="onSubmitUser()">{{btnText}}</button>
                <button type="button" class="btn dark btn-outline" (click)="closeModal()">
                    <span> Close</span>
                </button>
            </div>
        </div>
    </div>
</div>
<!-- END ADD USER -->