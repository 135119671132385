<!-- BEGIN HEADER -->
<div class="page-header navbar navbar-fixed-top" >
    <!-- BEGIN HEADER INNER -->
    <div class="page-header-inner w-100">
        <!-- BEGIN LOGO -->
        <div class="page-logo">
            <a href="/">
            	<img [src]="logo" alt="{{siteName}} Logo" class="logo-default" />
            </a>
            <div class="menu-toggler sidebar-toggler">
                <!-- DOC: Remove the above "hide" to enable the sidebar toggler button on header -->
            </div>
        </div>
        <!-- END LOGO -->
        <!-- BEGIN RESPONSIVE MENU TOGGLER -->
        <a href="javascript:;" class="menu-toggler responsive-toggler" data-toggle="collapse" data-target=".navbar-collapse"> </a>
        <!-- END RESPONSIVE MENU TOGGLER -->
        <!-- BEGIN PAGE ACTIONS -->
        <!-- DOC: Remove "hide" class to enable the page header actions -->
        <div class="page-actions" *ngIf="currentUser['roleId'] == '-LMF_wjm1JEn9DZxH87o'">
            <div class="btn-group">
                <button type="button" class="btn blue btn-sm dropdown-toggle" data-toggle="dropdown" data-hover="dropdown" data-close-others="true">
                    <span class="hidden-sm hidden-xs">{{siteName}} </span>
                    <i class="fa fa-angle-down"></i>
                </button>
                <ul class="dropdown-menu" role="menu">
                    <li class="">
                        <a routerLink = "/company">
                            <i class="icon-docs"></i> Information </a>
                    </li>
                    <li class="">
                        <a href="javascript:;">
                            <i class="icon-settings"></i> Settings
                        </a>
                    </li>
                    <li class="divider"> </li>
                    <li>
                        <a routerLink = "/users">
                            <i class="icon-people"></i> User Managnement
                        </a>
                    </li>
                    <li>
                        <a routerLink = "/roles">
                            <i class="icon-user-following"></i> Role Management
                        </a>
                    </li>
                    <li>
                        <a routerLink = "/menu">
                            <i class="icon-menu"></i> Menu Management
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <!-- END PAGE ACTIONS -->
        <!-- BEGIN PAGE TOP -->
        <div class="page-top">
            <!-- BEGIN HEADER SEARCH BOX -->
            <!-- DOC: Apply "search-form-expanded" right after the "search-form" class to have half expanded search box -->
            <form class="search-form" action="" method="GET">
                <div class="input-group">
                    <input type="text" class="form-control input-sm" placeholder="Search..." name="query">
                    <span class="input-group-btn">
                        <a href="javascript:;" class="btn submit">
                            <i class="icon-magnifier"></i>
                        </a>
                    </span>
                </div>
            </form>
            <!-- END HEADER SEARCH BOX -->
            <!-- BEGIN TOP NAVIGATION MENU -->
            <div class="top-menu">
                <ul class="nav navbar-nav pull-right">
                    <li class="d-none hide">
                        <a routerLink = "/">{{siteName}}</a>
                    </li>
                    <li class="separator pull-left d-block"> </li>
                    <!-- BEGIN NOTIFICATION DROPDOWN -->
                    
                    <!-- BEGIN USER LOGIN DROPDOWN -->
                    <!-- DOC: Apply "dropdown-dark" class after below "dropdown-extended" to change the dropdown styte -->
                    <li class="dropdown dropdown-user dropdown-dark">
                        <a href="javascript:;" class="dropdown-toggle" data-toggle="dropdown" data-hover="dropdown" data-close-others="true" >
                            <span class="username username-hide-on-mobile" *ngIf="currentUser.firstname == ''"> {{currentUser.email}} </span>
                            <span class="username initial-name" *ngIf="currentUser.firstname != ''" style="background-color: {{initialNameBG['bg']}}; color: {{initialNameBG['color']}}"> {{initialName}}</span>
                            <!-- DOC: Do not remove below empty space(&nbsp;) as its purposely used -->
                        </a>
                        <ul class="dropdown-menu dropdown-menu-default">
                            <li>
                                <a routerLink = "/profile">
                                    <i class="icon-user"></i> My Profile </a>
                            </li>
                            <li class="hide">
                                <a routerLink = "/changepassword">
                                    <i class="icon-lock"></i> Change password </a>
                            </li>
                            <li class="divider"> </li>
                            <li>
                                <a (click)="logout()">
                                    <i class="icon-key"></i> Log Out 
                                </a>
                            </li>
                        </ul>
                    </li>
                    <!-- END USER LOGIN DROPDOWN -->
                </ul>
            </div>
            <!-- END TOP NAVIGATION MENU -->
        </div>
        <!-- END PAGE TOP -->
    </div>
    <!-- END HEADER INNER -->
</div>
<!-- END HEADER -->
<!-- BEGIN HEADER & CONTENT DIVIDER -->
<div class="clearfix" > </div>
<!-- END HEADER & CONTENT DIVIDER -->
<!-- BEGIN CONTAINER -->
<div class="page-container" >
    <!-- BEGIN SIDEBAR -->
    <div class="page-sidebar-wrapper">
        <!-- BEGIN SIDEBAR -->
        <!-- DOC: Set data-auto-scroll="false" to disable the sidebar from auto scrolling/focusing -->
        <!-- DOC: Change data-auto-speed="200" to adjust the sub menu slide up/down speed -->
        <div class="page-sidebar navbar-collapse collapse">
            <!-- BEGIN SIDEBAR MENU -->
            <!-- DOC: Apply "page-sidebar-menu-light" class right after "page-sidebar-menu" to enable light sidebar menu style(without borders) -->
            <!-- DOC: Apply "page-sidebar-menu-hover-submenu" class right after "page-sidebar-menu" to enable hoverable(hover vs accordion) sub menu mode -->
            <!-- DOC: Apply "page-sidebar-menu-closed" class right after "page-sidebar-menu" to collapse("page-sidebar-closed" class must be applied to the body element) the sidebar sub menu mode -->
            <!-- DOC: Set data-auto-scroll="false" to disable the sidebar from auto scrolling/focusing -->
            <!-- DOC: Set data-keep-expand="true" to keep the submenues expanded -->
            <!-- DOC: Set data-auto-speed="200" to adjust the sub menu slide up/down speed -->
            <ul class="page-sidebar-menu" data-keep-expanded="false" data-auto-scroll="true" data-slide-speed="200">
                <li class="heading bg-green bg-font-green">
                    <h3 class="uppercase font-white c-text-center">
                        <p id="date-now">{{today | date: 'fullDate'}}</p>
                        <p id="time-now">{{today | date: 'mediumTime'}}</p>
                    </h3>
                </li>
                <li class="nav-item start hide" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }" *ngFor="let menu of menus | slice:0:1">
                    <a routerLink = "/{{menu['link']}}" class="nav-link">
                        <i class="{{menu['icon']}}"></i>
                        <span class="title">{{menu['name']}}</span>
                    </a>
                </li>
                <!-- | slice:1:menus.length -->
                <li class="nav-item start" [routerLinkActive]="['active']" *ngFor="let menu of userPermission"> 
                    <a routerLink = "/{{menu['link']}}" class="nav-link" *ngIf="!menu['submenu']">
                        <i class="{{menu['icon']}}"></i>
                        <span class="title">{{menu['name']}}</span>
                    </a>
                    <a href="javascript:;" class="nav-link nav-toggle" *ngIf="menu['submenu']">
                        <i class="{{menu['icon']}}"></i>
                        <span class="title">{{menu['name']}}</span>
                        <span class="selected" *ngIf="menu['submenu']"></span>
                        <span class="arrow" *ngIf="menu['submenu']"></span>
                    </a>
                    <ul class="sub-menu" *ngIf="menu['submenu']"s>
                        <li class="nav-item" [routerLinkActive]="['active']" *ngFor="let submenu of getSubMenusById(menu['id'])">
                            <a routerLink = "/{{submenu['link']}}" class="nav-link" *ngIf="!submenu['submenuL2']"> 
                                <i class="{{submenu['icon']}}"></i>
                                <span class="title">{{submenu['name']}}</span>
                            </a>
                            <a href="javascript:;" class="nav-link nav-toggle" *ngIf="submenu['submenuL2']">
                                <i class="{{submenu['icon']}}"></i>
                                <span class="title">{{submenu['name']}}</span>
                                <span class="selected"></span>
                                <span class="arrow"></span>
                            </a>
                            <ul class="sub-menu" *ngIf="submenu['submenuL2']">
                                <li  class="nav-item" [routerLinkActive]="['active']" *ngFor="let submenuL2 of getSubMenusL2ById(submenu['id'])">
                                    <a routerLink = "/{{submenuL2['link']}}" class="nav-link ">
                                        <i class="{{submenuL2['icon']}}"></i>
                                        <span class="title">{{submenuL2['name']}}</span>
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
            </ul>
            <!-- END SIDEBAR MENU -->
        </div>
        <!-- END SIDEBAR -->
    </div>
    <!-- END SIDEBAR -->
    <!-- BEGIN CONTENT -->
    <div class="page-content-wrapper">
        <!-- BEGIN CONTENT BODY -->
        <div class="page-content" id="page-content">
            <router-outlet></router-outlet>
        </div>
        <!-- END CONTENT BODY -->
    </div>
    <!-- END CONTENT -->
</div>
<!-- END CONTAINER -->

<!-- BEGIN FOOTER -->
<div class="page-footer" >
    <div class="page-footer-inner"> 2019 &copy; <a href="{{website}}" target="_blank" class="">{{siteName}}</a>
        <span><br>Designed by <a href="http://www.dothang.net" target="_blank" class="">dothang.net</a>.</span>
    </div>
    <div class="scroll-to-top">
        <i class="icon-arrow-up"></i>
    </div>
</div>
<!-- END FOOTER -->

<!-- LOADING -->
<div class="loader d-none hide"> 
  <div class="bg-loading loading">
    <div style="background-image: url('../../assets/imgs/loading.gif');" class="c-loading"></div>
    <!-- <i class="fa fa-spinner fa-pulse fa-3x fa-fw c-font-white"></i> -->
  </div>
</div>
<!-- LOADING -->