import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl } from "@angular/forms";
import { Http, Response, Headers } from '@angular/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';
import * as $ from 'jquery';

import { MenuModel, SubmenuModel, MenuService, 
	     LibsService, PagerService, LocalData } from '../_services';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  menus= [];
  allSubmenus = [];
  submenus = [];
  submenusL2 = [];
  menu: MenuModel = new MenuModel();
  submenu: SubmenuModel = new SubmenuModel();
  submenuL2: SubmenuModel = new SubmenuModel();
  menuKey = '';
  submenuKey = '';
  submenuL2Key = '';

  menuForm: FormGroup;
  submenuForm: FormGroup;
  submenuL2Form:FormGroup;

  title = 'Add';
  btnText = 'Submit';
  titleSub = 'Add';
  btnTextSub = 'Submit';
  titleSubL2 = 'Add';
  btnTextSubL2 = 'Submit';
  errorMessage = '';
  errorMessageSub = '';

  isValidName = true;
  isValidIcon = true;
  isValidHasChild = false;
  isValidLink = true;
  isValidOrder = true;

  isValidMenu = true;
  isValidSubmenuName = true;
  isValidSubmenuIcon = true;
  isValidHasChildL2 = false;
  isValidSubmenuLink = true;
  isValidSubmenuOrder = true;
  isSubmenuLoaded = false;

  isValidSubMenu = true;
  isValidSubmenuL2Name = true;
  isValidSubmenuL2Icon = true;
  isValidSubmenuL2Link = true;

  isExpandMenu =  false;
  isShowMenu = 'none';
  isExpandSubmenu = false;
  isShowSubmenu = 'none';
  isExpandSubmenuL2 = false;
  isShowSubmenuL2 = 'none';

  modalId = '';
  isModal = 'none';

  constructor(
  	private _libs: LibsService,
    private _localDB: LocalData,
  	private fb: FormBuilder,
    private menuService: MenuService,
  ) {
    this.menus = this._libs.getLocalStorageItem('menus');
    this.allSubmenus = this._libs.getLocalStorageItem('allSubmenus');
    
  }

  ngOnInit() {
  	this.createMenuForm();
  	this.createSubMenuForm();
  	this.createSubMenuL2Form();
  	if (this.menus.length == 0){
  		this.getMenusList();
  	}else{
      this.menus = this.menus.sort(this._libs.dynamicSortByName('order'));
      this.menu['order'] = this.menus.length + 1;
    }
    if (this.allSubmenus.length == 0){
      this.getSubmenusList();
    }else{
      this.isSubmenuLoaded = true;
      this.submenus = this.allSubmenus.filter(subl2 => subl2['isSubmenuL2'] == false);
      this.submenus = this.submenus.sort(this._libs.dynamicSortByName('order'));
      this.submenu['order'] = this.submenus.length + 1;

      this.submenusL2 = this.allSubmenus.filter(subl2 => subl2['isSubmenuL2'] == true);
      $.getScript('../../../assets/pages/scripts/ui-tree.min.js');
    }
  }

  menuEdit(id){
    this.menuKey = id;
    this.title = 'Edit';
    this.btnText = 'Update';
    this.menu = this.getMenu(id);
  }

  menuDelete(id, modalId){
    this.modalId = modalId;
    this.menu = this.getMenu(id);
    this.isModal = this._libs.openModal(modalId);
  }

  confirmYes(){
    // console.log(this.developer['id']);
    this.menuService.deleteMenu(this.menu['id']);
    this.closeModal();
  }

  openModal(modalId){
    this.modalId = modalId;
    this.isModal = this._libs.openModal(modalId);
  }

  closeModal(){
    this.modalId = '';
    this.menu = new MenuModel();
    this.isModal = this._libs.closeModal();
  }

  expandMenuBox(){
    if (this.isExpandMenu){
      this.isExpandMenu = false;
      this.isShowMenu = 'none';
    }else{
      this.isExpandMenu = true;
      this.isShowMenu = 'block';
    }
  }

  expandSubmenuBox(){
    if (this.isExpandSubmenu){
      this.isExpandSubmenu = false;
      this.isShowSubmenu = 'none';
    }else{
      this.isExpandSubmenu = true;
      this.isShowSubmenu = 'block';
    }
  }

  expandSubmenuL2Box(){
    if (this.isExpandSubmenuL2){
      this.isExpandSubmenuL2 = false;
      this.isShowSubmenuL2 = 'none';
    }else{
      this.isExpandSubmenuL2 = true;
      this.isShowSubmenuL2 = 'block';
    }
  }

  hasSubmenu(e: any){
    // console.log(e.target.checked);
    if (e.target.checked){
      this.errorMessage = '';
      this.isValidHasChild = true;
    }else{
      this.errorMessage = 'If this menu does not have submenu, please add the link below';
      this.isValidHasChild = false;
    }
  }

  hasSubmenuL2(e: any){
    // console.log(e.target.checked);
    if (e.target.checked){
      this.errorMessage = '';
      this.isValidHasChildL2 = true;
    }else{
      this.errorMessage = 'If this submenu does not have submenu level 2, please add the link below';
      this.isValidHasChildL2 = false;
    }
  }
  ///MENU
  createMenuForm() {
    this.menuForm = this.fb.group({
      name: new FormControl('', Validators.required),
      link : new FormControl(),
      icon : new FormControl(),
      submenu : new FormControl(),
      order : new FormControl(),
    });
  }

  getMenu(id){
  	this.menuKey = id;
    let menu = this.menus.filter(menu => menu['id'] == id);
    return this._libs.convert2MenuModel(menu[0]);
  }

  getMenusList(){
  	this.menuService.getMenusList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ id: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(menus => {
      this.menus = menus;
      // console.log(this.menus);
      this.menus = this.menus.sort(this._libs.dynamicSortByName('order'));
      this._libs.setLocalStorageItem('menus', this.menus);
      this.menu['order'] = this.menus.length + 1;
    });
  }

  onSubmit() {
    let name = this.menuForm.controls['name'].value;
    let icon = this.menuForm.controls['icon'].value;
    let link = this.menuForm.controls['link'].value;
    let submenu = this.menuForm.controls['submenu'].value;
    let order = this.menuForm.controls['order'].value;
    
    if(name == '' || name == null){
      this.isValidName = false
      return;
    }else{
      this.isValidName = true;
    }
    if(icon == '' || icon == null){
      this.isValidIcon = false
      return;
    }else{
      this.isValidIcon = true;
    }
    if(submenu == false){
      this.isValidHasChild = false;
    }else{
      this.isValidHasChild = true;
    }
    if(!this.isValidHasChild){
      if (link == '' || link == null){
        this.errorMessage = 'If this menu does not have submenu, please add the link below';
        this.menu['link'] = '';
        this.isValidLink = false
        return;
      }else{
        this.errorMessage = '';
        this.isValidLink = true;
      }
    }
    if(order == false){
      this.isValidOrder = false;
    }else{
      this.isValidOrder = true;
    }

    // this.developer = this.form.value;
    this.menu['link'] = this.menu['link'].replace(/ /g,'');
    // console.log(this.menu);
    if (this.menuKey){
      this.updateMenu(this.menu);
    }else{
      this.saveMenu(this.menu);
    }
  }

  updateMenu(f){
    // console.log(f);
    this.menuService.updateMenu(this.menuKey, f);
    this.resetMenu();
  }

  saveMenu(f) {
    this.menuService.createMenu(f);
    this.resetMenu();
  }

  resetMenu(){
    this.getMenusList();
    this.title = 'Add';
    this.btnText = 'Submit';
    this.menuForm.reset();
    this.menuKey = '';
    this.menu = new MenuModel();
    // console.log(this.menu);
  }

  editMenu(id){
    // console.log(id);
    this.title = 'Edit';
    this.btnText = 'Update';
  	this.isExpandMenu = true;
    this.isShowMenu = 'block';
    this.isExpandSubmenu = false;
    this.isShowSubmenu = 'none';
    this.isExpandSubmenuL2 = false;
    this.isShowSubmenuL2 = 'none';
  	this.menu = this.getMenu(id);
  }

  deteleMenu(id){
    // console.log(id);
    // console.log(this.submenus);
    // console.log(this.submenusL2);
    var tempSubMenuArr = this.submenus.filter(ft => ft['menuId'] == id);
    if (tempSubMenuArr.length > 0){
      // console.log(tempSubMenuArr);
      for (var i = 0; i < tempSubMenuArr.length; ++i) {
        var tempSubL2 = this.submenusL2.filter(ft => ft['menuId'] == tempSubMenuArr[i]['id'])
        if (tempSubL2.length > 0){
          // console.log(tempSubL2);
          for (var j = 0; j < tempSubL2.length; ++j) {
            this.menuService.deleteSubmenu(tempSubL2[j]['id']);
          }
        }else{
          this.menuService.deleteSubmenu(tempSubMenuArr[i]['id']);
        }
      }
      // this.menuService.deleteMenu(id);
    // }else{
    }
    this.menuService.deleteMenu(id);
    this.getMenusList();
  }

  ///SUBMENU
  createSubMenuForm() {
    this.submenuForm = this.fb.group({
      name: new FormControl('', Validators.required),
      link : new FormControl(),
      icon : new FormControl(),
      menuId : new FormControl(),
      submenuL2: new FormControl(),
      isSubmenuL2: new FormControl(),
      order: new FormControl(),
    });
  }

  getSubmenusList(){
  	this.menuService.getSubmenusList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ id: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(allSubmenus => {
      this.allSubmenus = allSubmenus;
      this.submenus = this.allSubmenus.filter(subl2 => subl2['isSubmenuL2'] == false);
      this.submenus = this.submenus.sort(this._libs.dynamicSortByName('order'));
      this.submenu['order'] = this.submenus.length + 1;
      
      this.submenusL2 = this.allSubmenus.filter(subl2 => subl2['isSubmenuL2'] == true);
      this.submenusL2 = this.submenusL2.sort(this._libs.dynamicSortByName('order'));
      this.submenusL2['order'] = this.submenusL2.length + 1;
      
      // this._libs.setLocalStorageItem('allSubmenus', this.allSubmenus);
      this.isSubmenuLoaded = true;
      $.getScript('../../../assets/pages/scripts/ui-tree.min.js');
      // console.log(this.submenus);
    });
  }

  getSubMenusById(id){
  	// console.log(id);
  	return this.submenus.filter(submenu => submenu['menuId'] == id);
  }

  getSubMenusL2ById(id){
  	// console.log(id);
  	return this.submenusL2.filter(submenu => submenu['menuId'] == id);
  }

  getSubMenu(id){
    this.submenuKey = id;
    let submenu = this.allSubmenus.filter(submenu => submenu['id'] == id);
    return this._libs.convert2SubMenuModel(submenu[0]);
  }

  onSubmitSubmenu() {
    let menuId = this.submenuForm.controls['menuId'].value;
    let name = this.submenuForm.controls['name'].value;
    let link = this.submenuForm.controls['link'].value;
    let icon = this.submenuForm.controls['icon'].value;
    let submenuL2 = this.submenuForm.controls['submenuL2'].value;
    
    if(menuId == '' || menuId == null){
      this.isValidMenu = false
      return;
    }else{
      this.isValidMenu = true;
      // this.menu = this.getMenu(menuId);
    }
    if(name == '' || name == null){
      this.isValidSubmenuName = false
      return;
    }else{
      this.isValidSubmenuName = true;
    }
    if(icon == '' || icon == null){
      this.isValidSubmenuIcon = false
      return;
    }else{
      this.isValidSubmenuIcon = true;
    }
    if(this.submenu['submenuL2'] == false){
      this.isValidHasChildL2 = false;
    }else{
      this.isValidHasChildL2 = true;
    }
    if(!this.isValidHasChildL2){
      if (link == '' || link == null){
      	this.errorMessageSub = 'If this submenu does not have submenu level 2, please add the link below';
        this.isValidSubmenuLink = false
        return;
      }else{
        this.submenu['submenuL2'] = false;
        this.errorMessage = '';
        this.isValidSubmenuLink = true;
    	this.submenu['link'] = this.submenu['link'].replace(/ /g,'');
      }
    }else{
      	this.submenu['link'] = ' ';
    }
        
    // this.developer = this.form.value;
    this.submenu['isSubmenuL2'] == false;
    // console.log(this.submenu);
    if (this.submenuKey){
      this.updateSubmenu(this.submenuKey, this.submenu);
    }else{
      this.saveSubmenu(this.submenu);
    }
  }

  updateSubmenu(k, f){
    // console.log(f);
    this.menuService.updateSubmenu(k, f);
    this.resetSubmenu();
  }

  saveSubmenu(f) {
    this.menuService.createSubmenu(f);
    this.resetSubmenu();
  }

  resetSubmenu(){
    this.getSubmenusList();
    this.title = 'Add';
    this.btnText = 'Submit';
    this.submenuForm.reset();
    this.submenu = new SubmenuModel();
    this.submenuKey = '';
    this.isValidHasChildL2 = false;
    this.errorMessageSub = '';
    // console.log(this.menu);
  }

  editSubmenu(id){
    this.titleSub = 'Edit';
    this.isExpandMenu = false;
    this.isShowMenu = 'none';
    this.isExpandSubmenu = true;
    this.isShowSubmenu = 'block';
    this.isExpandSubmenuL2 = false;
    this.isShowSubmenuL2 = 'none';
    this.submenu = this.getSubMenu(id);
  }

  deteleSubmenu(id){
    // console.log(id);
    this.menuService.deleteSubmenu(id);
    this.getSubmenusList();
  }

  ///SUBMENU L2
  createSubMenuL2Form() {
    this.submenuL2Form = this.fb.group({
      name: new FormControl('', Validators.required),
      link : new FormControl(),
      icon : new FormControl(),
      menuId : new FormControl(),
      submenuL2 : new FormControl(),
      isSubmenuL2 : new FormControl(),
    });
  }

  onSubmitSubmenuL2() {
    let menuId = this.submenuL2Form.controls['menuId'].value;
    let name = this.submenuL2Form.controls['name'].value;
    let icon = this.submenuL2Form.controls['icon'].value;
    let link = this.submenuL2Form.controls['link'].value;
    
    if(menuId == '' || menuId == null){
      this.isValidSubMenu = false
      return;
    }else{
      this.isValidSubMenu = true;
      // this.menu = this.getMenu(menuId);
    }
    if(name == '' || name == null){
      this.isValidSubmenuL2Name = false
      return;
    }else{
      this.isValidSubmenuL2Name = true;
    }
    if(icon == '' || icon == null){
      this.isValidSubmenuL2Icon = false
      return;
    }else{
      this.isValidSubmenuL2Icon = true;
    }
    if(link == '' || link == null){
      this.isValidSubmenuL2Link = false
      return;
    }else{
      this.isValidSubmenuL2Link = true;
    }
    this.submenuL2['submenuL2'] = false;
    this.submenuL2['isSubmenuL2'] = true;
    this.submenuL2['link'] = this.submenuL2['link'].replace(/ /g,'');
    // this.developer = this.form.value;
    // console.log(this.submenuL2);
    if (this.submenuL2Key){
      this.updateSubmenu(this.submenuL2Key, this.submenuL2);
    }else{
      this.saveSubmenu(this.submenuL2);
    }
    this.resetSubmenuL2();
  }

  resetSubmenuL2(){
    this.submenusL2 = this.submenus.filter(subl2 => subl2['isSubmenuL2'] == true) //getSubmenusL2List();
    this.title = 'Add';
    this.btnText = 'Submit';
    this.submenuL2 = new SubmenuModel();
    this.submenuL2Key = '';
    this.submenuL2Form.reset();
    // console.log(this.menu);
  }

  editSubmenuL2(id){
    this.titleSubL2 = 'Edit';
    this.submenuL2Key = id;
    this.isExpandMenu = false;
    this.isShowMenu = 'none';
    this.isExpandSubmenu = false;
    this.isShowSubmenu = 'none';
    this.isExpandSubmenuL2 = true;
    this.isShowSubmenuL2 = 'block';
    this.submenuL2 = this.getSubMenu(id);
  }

}