export class MassModel {
  name: string;
  churchId: string;
  videotype: string;
  link: string;
  date: string;
  time: string;
  timezone: string;
  timestamp: number;
  live: boolean;

  constructor(){
    this.name = "";
    this.churchId = "";
    this.videotype = '';
    this.link = "";
    this.date = '';
    this.time = '';
    this.timezone = '';
    this.timestamp = 0;
    this.live = false;
  }
}
