import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl } from "@angular/forms";
import { Http, Response, Headers } from '@angular/http';
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';

import { UserModel, UserService, 
		 MediaService,
		 LibsService, PagerService, MysqlService, LocalData, AuthenticationService } from '../../_services';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  form: FormGroup;
  currentUser: UserModel = new UserModel();
  // user: UserModel = new UserModel();
  key = '';
  title = 'Update';
  btnText = 'Update';
  // avatar: SafeResourceUrl;

  isValidFirstName = true;
  isValidLastName = true;
  isValidPhone = true;
  isValidOccupation = true;
  isValidAvatar = true;

  isModal = 'none';
  modalId = '';

  medias = [];
  today: number = Date.now();

  pager: any = {}; // pager object
  pagedItems: any[]; // paged items
  pageSize = 12;

  @ViewChild('mediaFileInput') mediaFileInputVariable: ElementRef;
  filesUpload = [];
  isLoaded = false;
  result = '';
  requireMessage = '';
  selectedFiles : FileList;

  isValidVideoNewPass = true;
  isValidVideoRetypeNewPass = true;
  isValidVideoCurrentPass = true;
  errorMessage = '';
  uploadUrl = this._localDB.uploadUrl;

  initialName = '';
  initialNameBG = {};

  constructor(
  	private userService: UserService,
  	private fb: FormBuilder,
    private _libs: LibsService,
    private _localDB: LocalData,
    private _mysql: MysqlService,
    private pagerService: PagerService,
    private authService: AuthenticationService,
    private mediaService: MediaService,
  ) { 
    this.currentUser = this._libs.getLocalStorageItem('currentUser');
    this.initialName = this.currentUser['firstname'].substr(0,1) + this.currentUser['lastname'].substr(0,1);
    this.initialNameBG = this._libs.getInitialBG((this.currentUser['firstname'] + this.currentUser['lastname']).length);
  }

  ngOnInit() {
    this._libs.loading();
  	this.createForm();
    this.key = this._libs.convert2OriginalId(this.currentUser['id']);

  }

  sanitizerUrl(value){
    return this._libs.getSantizeUrl(value);
  }
  
  createForm() {
    this.form = this.fb.group({
      firstname: new FormControl('', Validators.required),
      lastname: new FormControl('', Validators.required),
      phone: new FormControl('', Validators.required),
      ext: new FormControl(),
      cellphone: new FormControl(),
      email: new FormControl('', Validators.required),
      occupation: new FormControl('', Validators.required),
      about: new FormControl(),
      avatar: new FormControl(),
    });
  }

  onSubmit(){
    let firstname : string = (<HTMLInputElement>document.getElementById("firstname")).value; 
    let lastname : string = (<HTMLInputElement>document.getElementById("lastname")).value; 
    let phone : string = (<HTMLInputElement>document.getElementById("phone")).value; 
    let occupation : string = (<HTMLInputElement>document.getElementById("occupation")).value; 
    
    if(firstname == '' || firstname == null){
      this.isValidFirstName = false
      return;
    }else{
      this.isValidFirstName = true;
    }
    if(lastname == '' || lastname == null){
      this.isValidLastName = false
      return;
    }else{
      this.isValidLastName = true;
    }
    if(phone == '' || phone == null){
      this.isValidPhone = false
      return;
    }else{
      this.isValidPhone = true;
    }
    if(occupation == '' || occupation == null){
      this.isValidOccupation = false
      return;
    }else{
      this.isValidOccupation = true;
    }

    // if(this.user['avatar'] == ''){
    //   this.isValidAvatar = false
    //   return;
    // }else{
    //   this.isValidAvatar = true;
    // }

    // console.log(this._libs.convert2UserModel(this.currentUser));
    // console.log(this.key);
    if (this.key){
      this.update(this._libs.convert2UserModel(this.currentUser));
    }
  }  

  update(f){
    this.userService.updateUser(this.key, f);
    this._libs.setLocalStorageItem('currentUser', this.currentUser);
    this._libs.go2Page('dashboard');   
    this.reset();
  }

  save(f) {
    this.userService.createUser(f);
    this._libs.go2Page('dashboard');   
    this.reset();
  }

  cancel(){
    this._libs.go2Page('dashboard');
  }

  reset(){
    // this.form.reset();
    this.key = '';
    this.filesUpload = [];
    this.mediaFileInputVariable.nativeElement.value = '';
  }

  openModal(modalId){
  	// this._libs.loading();
    this.modalId = modalId;
    this.isModal = this._libs.openModal(modalId);
  }

  closeModal(){
    this.modalId = '';
    this.isModal = this._libs.closeModal();
  }

  onSubmitUpdatePassword(){
  	let currentPassword : string = (<HTMLInputElement>document.getElementById("current-password")).value; 
  	let newPassword : string = (<HTMLInputElement>document.getElementById("new-password")).value; 
    let retypeNewPassword : string = (<HTMLInputElement>document.getElementById("retype-new-password")).value; 

    if(currentPassword == '' || currentPassword == null){
      this.isValidVideoCurrentPass = false
      this.errorMessage = 'Current Password is required';
      return;
    }else{
    	if (currentPassword === newPassword){
    	  this.isValidVideoCurrentPass = false
	      this.errorMessage = 'Your new password matches your current';
	      return;
    	}else{
      	  this.isValidVideoCurrentPass = true;
    	}
    }
    if(newPassword == '' || newPassword == null){
      this.isValidVideoNewPass = false
      this.errorMessage = 'Passwords is required';
      return;
    }else{
      this.isValidVideoNewPass = true;
    }
    if(retypeNewPassword == '' || retypeNewPassword == null){
      this.isValidVideoRetypeNewPass = false
  	  this.errorMessage = 'Passwords do not match!';
      return;
    }else{
      if (newPassword !== retypeNewPassword){
      	this.isValidVideoRetypeNewPass = false
      	this.errorMessage = 'Passwords do not match!';
      	return;
      }else{
      	this.errorMessage = '';
      	this.isValidVideoRetypeNewPass = true;
      }
    }
    this.updatePassword(currentPassword, newPassword);
  }

  updatePassword(oldPass, newPass){
  	this.currentUser['password'] = newPass;
  	this.authService.doUpdatePassword(oldPass, newPass)
    .then(res => {
      // console.log(res);
      this.update(this._libs.convert2UserModel(this.currentUser));
  		(<HTMLInputElement>document.getElementById("current-password")).value = '';
  		(<HTMLInputElement>document.getElementById("new-password")).value = '';
  		(<HTMLInputElement>document.getElementById("retype-new-password")).value = '';
  		this.errorMessage = res.message;
    }, err => {
    	this.isValidVideoCurrentPass = false;
      	// console.log(err);
		  (<HTMLInputElement>document.getElementById("current-password")).value = '';
		  this.errorMessage = err.message;
    });
  }

  ///CATEGORY
  // getCategoriesList(){
  //   this.categoryService.getCategoriesList().snapshotChanges().pipe(
  //     map(changes =>
  //       changes.map(c => ({ 'id': c.payload.key, ...c.payload.val() }))
  //     )
  //   ).subscribe(categories => {
  //     this.categories = categories;
  //     // this._libs.setLocalStorageItem('categories', categories);
  //     // console.log(this.categories);
  //     this.getCurrentUser();
  //   });
  // }

  ///MEDIA
  // getMediasByCategory(cId): void {
  //   this._mysql.getAllImagesByCategory(cId)
  //   .subscribe( (response: Response) => {
  //       this.medias = response.json();
  //       console.log(this.medias);
  //     //   if (this.user['avatar'] == "noavatar" || this.user['avatar'] == "noavatar.jpg"){
  // 	  	// 	this.avatar = this._localDB.noavatar;
  // 	  	// }else{
  // 	  	// 	this.avatar = this.getImgCode(this.user['avatar']);
  // 	  	// }
  //       this.key = this._libs.convert2OriginalId(this.currentUser['id']);
  //       // console.log(this.avatar);
  //       // this._libs.unLoading();
  //   });
  // }

  selectedAvatar(id){
    for (var i = 0; i < this.medias.length; ++i) {
      if (this.medias[i] == id){
        return true;
      }
    }
  }

  chooseAvatar(id){
    this.currentUser['avatar'] = id;
    // this.avatar = this.getImgCode(this.user['avatar']);
    this.closeModal();
  }

  // getMedia(id){
  //   return this.medias.filter(media => media['id'] == id);
  // }

  // getImgCode(id){
  //   let avatarImg = this.getMedia(id);
  //   if (avatarImg != undefined){
  //     return this.sanitizerUrl(avatarImg[0]['imgCode']);
  //   }
  // }

  addMediaModal(modalId){
  	this.closeModal();
  	this.modalId = modalId;
    this.isModal = this._libs.openModal(modalId);
  }

  onUpload(event: any){
    this.selectedFiles = event.target.files;
    // console.log(this.selectedFiles);
    this.filesUpload = [];
    // let uploadedFiles = {};
    if(event.target.files && event.target.files.length > 0) {
      for (var f = 0; f < event.target.files.length; ++f) {
      let reader = new FileReader();
        let file = event.target.files[f];
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.filesUpload.push({'imgCode': reader.result, 'name': file['name'], 'type': file['type'], 'size': file['size'], 'uploadat': this.today});
        };
      }
      this.isLoaded = true;
    }
  }

  uploadAll() {
    for (var i = 0; i < this.selectedFiles.length; ++i) {
      for (var c = 0; c < this.filesUpload.length; ++c) {
        if (this.selectedFiles[i]['name'] == this.filesUpload[c]['name']){
          let tempType = this.selectedFiles[i]['type'];
          let tempExt = tempType.split('/');
          let filename = this.currentUser['firstname']+this.currentUser['lastname']+i+'.'+tempExt[tempExt.length - 1];
          this.removeAllFiles();
          this.closeModal();
          this.openModal('listMedias');
        }
      }
    }
  }

  removeFile(fn){
    this.filesUpload = this.filesUpload.filter(filesUp => filesUp.name != fn);
    if (this.filesUpload.length == 0){
      this.isLoaded = false;
      this.mediaFileInputVariable.nativeElement.value = '';
    }
    // console.log(this.filesUpload);
  }

  removeAllFiles(){
    this.filesUpload = [];
    this.mediaFileInputVariable.nativeElement.value = '';
    // this.isLoaded = false;
  }

  convertSize(size){
    return this._libs.convertSize(size);
  }

  ///PAGE
  setPage(page: number) {
      // get pager object from service
      this.pager = this.pagerService.getPager(this.medias.length, page, this.pageSize);
      // console.log(this.pager);
     this.pagedItems = this.medias.slice(this.pager.startIndex, this.pager.endIndex + 1);
      // console.log(this.pagedItems);
  }

}
