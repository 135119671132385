import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Http, Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { AngularFirestore, AngularFirestoreDocument } from 'angularfire2/firestore';
import { AngularFireDatabaseModule, AngularFireDatabase, AngularFireList, AngularFireObject } from 'angularfire2/database';
import * as firebase from 'firebase/app';

import { UserModel } from './user.model';


@Injectable({
  providedIn: 'root'
})
export class UserService {
  usersRef: AngularFireList<UserModel> = null;

  constructor(
    private router: Router,
    private afStore: AngularFirestore,
    private afData: AngularFireDatabase,
  ) { 
    this.usersRef = afData.list('/users');
  }

  getUsersList(): AngularFireList<UserModel> {
    return this.usersRef;
  }

  getUser(id): AngularFireList<UserModel> {
    return this.afData.list('/users/'+id);
  }

  updateUser(key, value) {
    this.usersRef.set(key, value);
  }

  createFirebaseUser(email, password){

  }

  setUser(key: string, value: UserModel): void {
    var ref = firebase.database().ref("users");
    ref.child(key).set(value);
  }

  createUser(value: UserModel): void {
    this.usersRef.push(value);
  }

  deleteUser(key: string): void {
    this.usersRef.remove(key).catch(error => this.handleError(error));
  }
  
  getCurrentUser1(){
    return new Promise<any>((resolve, reject) => {
      var user = firebase.auth().onAuthStateChanged(function(user){
        if (user) {
          resolve(user);
        } else {
          reject('No user logged in');
        }
      })
    })
  }

  private handleError(error) {
    console.log(error);
  }

}

