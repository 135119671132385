<div class="row">
	<div class="col-md-6 col-xs-6">
		<div class="portlet light bordered">
            <div class="portlet-title">
                <div class="caption font-dark">
                    <i class="icon-settings font-dark hide"></i>
                    <span class="caption-subject bold uppercase">{{title}} Church</span>
                </div>
            </div>
            <div class="portlet-body form">
                <form role="form" [formGroup]="form" (ngSubmit)="onSubmit()">
                    <div class="form-body">
	                    <div class="row">
	                    	<div class="col-md-6 col-xs-12">
		                    	<div class="form-group required">
			                        <label>Name</label>
			                        <input type="text" class="form-control" placeholder="Church Name" name="name" id="name" formControlName="name" [(ngModel)]="church.name">
			                    </div>
	                    	</div>
                            <div class="col-md-2 col-xs-12">
                                <div class="form-group">
                                    <label>Active</label>
                                    <div class="mt-checkbox-list">
                                        <label class="mt-checkbox mt-checkbox-outline">
                                            <input type="checkbox" id="active" name="active" class="c-check" [ngModelOptions]="{standalone: true}"[(ngModel)]="church.active">
                                            <span></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 col-sm-12">
                                <div class="form-group">
                                    <label>Address is in the U.S</label>
                                    <div class="mt-checkbox-list">
                                        <label class="mt-checkbox mt-checkbox-outline">
                                            <input type="checkbox" id="us-address" name="us-address" class="c-check" (change)="outsideUS($event.target.checked)" [checked]="isUS">
                                            <span></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
	                    	<div class="col-md-12 col-xs-12">
			                    <div class="form-group">
			                        <label>Address</label>
			                        <input type="text" class="form-control" placeholder="Address" name="address" id="address" formControlName="address" [(ngModel)]="church.address"> 
			                    </div>
	                    	</div>
		                    <div class="col-md-4 col-xs-12">
			                    <div class="form-group required">
			                        <label>City</label>
			                        <input type="text" class="form-control" placeholder="City" name="city" id="city" formControlName="city" [(ngModel)]="church.city"> 
			                    </div>
			                </div>
			                <div class="col-md-4 col-xs-12">
			                    <div class="form-group required">
                                    <label>State</label>
                                    <select class="form-control c-square" id="state" name="state" [(ngModel)]="church['state']" formControlName="state" *ngIf="isUS">
                                        <option value="">Select State</option>
                                        <option *ngFor="let st of states" [value]="st.code">{{st.name}}</option>
                                    </select>
                                    <input type="text" class="form-control" placeholder="State" name="state" id="state" formControlName="state" [(ngModel)]="church.state" *ngIf="!isUS">
                                </div>
			                </div>
			                <div class="col-md-4 col-xs-12">
			                    <div class="form-group">
			                        <label>Zip Code</label>
			                        <input type="text" class="form-control" placeholder="Zip Code" name="zipcode" id="zipcode" formControlName="zipcode" [(ngModel)]="church.zipcode"> 
			                    </div>
			                </div>
                        </div>
                        <div class="row">
			                <div class="col-md-6 col-xs-12">
			                	<div class="form-group">
		                            <label>Email</label>
		                            <div class="input-group">
		                                <span class="input-group-addon">
		                                    <i class="fa fa-envelope"></i>
		                                </span>
		                                <input type="text" class="form-control" placeholder="Email Address" name="email" id="email" formControlName="email" [(ngModel)]="church.email"> 
		                            </div>
		                        </div>
			                </div>
			                <div class="col-md-6 col-xs-12">
			                	<div class="form-group">
		                            <label>Phone</label>
		                            <div class="input-group">
		                                <span class="input-group-addon">
		                                    <i class="fa fa-phone"></i>
		                                </span>
		                                <input type="text" class="form-control" placeholder="Phone" name="phone" id="phone" formControlName="phone" [(ngModel)]="church.phone"> 
		                            </div>
		                        </div>
			                </div>
			                <div class="col-md-12 col-xs-12">
					            <div class="form-group">
		                            <label>Description</label>
                                    <angular-editor [placeholder]="'Enter text here...'" [config]="editorConfig" formControlName="description" [(ngModel)]="church.description"></angular-editor>
		                        </div>
		                    </div>
		                    
			            </div>
                    </div>
                    <div class="form-actions">
                        <button type="submit" class="btn blue">{{btnText}}</button>
                        <button type="button" class="btn default" (click)="reset()">Cancel</button>
                    </div>
                </form>
            </div>
        </div>
	</div>

	<!-- LIST ALL CHURCHES -->
	<div class="col-md-6 col-sm-6">
        <div class="portlet light portlet-fit bordered">
            <div class="portlet-title">
                <div class="caption">
                    <i class="icon-microphone font-dark hide"></i>
                    <span class="caption-subject bold font-dark uppercase">All Churches</span>
                </div>
                <div class="actions">
                    <div class="btn-group btn-group-devided hide" data-toggle="buttons">
                        <label class="btn red btn-outline btn-circle btn-sm active">
                            <input type="radio" name="options" class="toggle" id="option1">Settings</label>
                        <label class="btn  red btn-outline btn-circle btn-sm">
                            <input type="radio" name="options" class="toggle" id="option2">Tools</label>
                    </div>
                    <div class="input-icon right c-margin-l-r-5" style="float: right;">
                        <i class="icon-magnifier"></i>
                        <input type="text" class="form-control form-control-solid" placeholder="Search by Name..." (input)="searchChurch($event.target.value)"> 
                    </div>
                </div>
            </div>
            <div class="portlet-body">
                <div class="table-scrollable">
                    <table class="table table-striped table-bordered table-advance table-hover">
                        <thead>
                            <tr>
                                <th class="col-md-5 col-xs-2"> Name </th>
                                <th class="col-md-5 col-xs-2"> Address </th>
                                <th class="col-md-2 col-xs-1">  </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let church of pagedItems ">
                                <td class="">{{church['name']}}</td>
                                <td>{{church.city}}, {{church.state}} {{church.zipcode}}</td>
                                <!-- <td>{{church['active']}}</td> -->
                                <td>
                                    <a (click)="churchEdit(church.id)" class="btn dark btn-sm btn-outline sbold uppercase">
                                        <i class="fa fa-pencil"></i>  
                                    </a>
                                    <a (click)="churchDelete(church.id, 'delete')" class="btn red btn-sm btn-outline sbold uppercase">
                                        <i class="fa fa-trash"></i>  
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="row">
                    <div class="col-md-12 col-xs-12 text-right c-padding-b-20">
                        <!-- BEGIN: PAGE CONTENT -->
                        <ul *ngIf="pager.pages && pager.pages.length" class="pagination bootpag">
                            <li [ngClass]="{disabled:pager.currentPage === 1}">
                                <a (click)="setPage(1)"><i class="fa fa-angle-double-left"></i></a>
                            </li>
                            <li [ngClass]="{disabled:pager.currentPage === 1}">
                                <a (click)="setPage(pager.currentPage - 1)"><i class="fa fa-angle-left"></i></a>
                            </li>
                            <li *ngFor="let page of pager.pages" [ngClass]="{'c-active': pager.currentPage === page}">
                                <a (click)="setPage(page)">{{page}}</a>
                            </li>
                            <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                                <a (click)="setPage(pager.currentPage + 1)"><i class="fa fa-angle-right"></i></a>
                            </li>
                            <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                                <a (click)="setPage(pager.totalPages)"><i class="fa fa-angle-double-right"></i></a>
                            </li>
                        </ul>
                    <!-- END: PAGE CONTENT -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade hide bs-modal-lg" id="view" tabindex="-1" role="dialog" aria-hidden="true" [ngStyle]="{'display': isModal}" style="padding-right: 17px;">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="closeModal()" ></button>
                <h4 class="modal-title">{{church.name}}</h4>
            </div>
            <div class="modal-body">
                <dl class="dl-horizontal">
                    <dt>Address</dt>
                    <dd>{{church['address']}}, {{church['city']}} {{church['state']}} {{church['zipcode']}}</dd>
                    <dt>Description</dt>
                    <dd>{{church['description']}}</dd>
                </dl>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn dark btn-outline" data-dismiss="modal" (click)="closeModal()" >Close</button>
            </div>
        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>

<div class="modal fade hide" id="delete" tabindex="-1" role="basic" aria-hidden="true" style="padding-right: 17px;" [ngStyle]="{'display': isModal}">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="closeModal()"></button>
                <h4 class="modal-title">Confirmation</h4>
            </div>
            <div class="modal-body"> Are you sure you want to delete currentPage '{{church['name']}}'' ? </div>
            <div class="modal-footer">
                <button type="button" class="btn red" (click)="confirmYes()" > Yes </button>
                <button type="button" class="btn dark btn-outline" data-dismiss="modal" (click)="closeModal()">No</button>
            </div>
        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>
