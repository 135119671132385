import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl } from "@angular/forms";
import { Http, Response, Headers } from '@angular/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';
import * as $ from 'jquery';
import { AngularEditorConfig } from '@kolkov/angular-editor';

import { ChurchModel, ChurchService, 
		MediaService, MediaModel, 
		LibsService, PagerService, MysqlService, LocalData } from '../_services';

@Component({
  selector: 'app-churches',
  templateUrl: './churches.component.html',
  styleUrls: ['./churches.component.scss']
})
export class ChurchesComponent implements OnInit {
  church: ChurchModel = new ChurchModel();
  churches = [];
  key ='';

  form: FormGroup;
  requiredFields = [];
  isValidName = true;
  isValidCity = true;
  isValidState =  true;
  isUS = true;

  btnText = 'Submit';
  title = 'Add';
  isModal = 'none';

  states = (this._localDB.states).sort(this._libs.dynamicSortByName('name'));

  modalId = '';
  backpage = '';

  pager: any = {}; // pager object
  pagedItems: any[]; // paged items
  pageSize = 12;
  currentPage = 1;

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '100px',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes'
  }

  constructor(
    private _localDB: LocalData,
    private _libs: LibsService,
    private pagerService: PagerService,
  	private churchService: ChurchService,
  	private formBuilder: FormBuilder,
  ) { 
    this.churches = this._libs.getLocalStorageItem('churches');
    this.form = this.formBuilder.group({
      name: new FormControl('', [Validators.minLength(2), Validators.required]),
	    address: new FormControl(''),
	    zipcode: new FormControl(''),
	    city: new FormControl('', Validators.required),
	    state: new FormControl('', Validators.required),
	    description: new FormControl(''),
	    website: new FormControl('' ),
	    email: new FormControl('', Validators.email),
	    phone: new FormControl('' ),
      active: true,
    });

  }

  ngAfterViewInit() {
    this. requiredFields = this._libs.makeRequirementField();
    // console.log(this.requiredFields);
  }

  ngOnInit(): void {
    this.setPage(this.currentPage);
    $('input').on('input', function(e) {
  	  if ($(this).parent().hasClass('required')){
  	  	if ($(this).parent().hasClass('error') && $('#'+e.currentTarget.id).val() != ''){
  		  	$(this).parent().removeClass('error');
  		  	$(this).parent().find('.error-label').remove();
  	  	}
  	  	if (!$(this).parent().hasClass('error') && $('#'+e.currentTarget.id).val() == ''){
      	  	$(this).parent().addClass('error');
              $(this).parent().append('<span class="form-text text-muted font-red small error-label">This field is required.</span>');
  	  	}
  	  }
  	});
  }

  outsideUS(e){
    // console.log(e);
    this.isUS = e;
  }

  getChurchesList(){
  	this.churchService.getChurchesList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ id: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(churches => {
      this.churches = churches.sort(this._libs.dynamicSortByName('name'));
      this._libs.setLocalStorageItem('churches', this.churches);
      // console.log(this.churches);
      this.setPage(this.currentPage);
    });
  }

  onSubmit() {
    // console.log(this.church);
  	if (this._libs.validatorFields(this.requiredFields)){
  		return
  	}
    this.church = this._libs.convert2ChurchModel(this.church);
    if (this.key){
      this.update(this.church);
    }else{
      this.church['timestamp'] = this._libs.getTimestamp();
      this.save(this.church);
    }
  }

  update(f){
    // console.log(f);
    this.churchService.updateChurch(this.key, f);
    this.reset();
  }

  save(f) {
    this.churchService.createChurch(f);
    this.reset();
  }

  reset(){
    this.getChurchesList();
    this.title = 'Add';
    this.btnText = 'Submit';
    this.church = new ChurchModel();
    this.key = '';
    // console.log(this.church);
  }

  churchEdit(id){
    this.btnText = 'Update';
    this.title = 'Edit';
    this.church = this.getChurch(id);
  	// console.log(this.church['features']);
    // console.log(this.tempServices);
    
  }

  getChurch(id){
    let tempChurch = new ChurchModel();
    this.key = id;
    var temp = this.churches.filter(ft => ft['id'] == id);
    return temp[0];
  }

  activeChurch(id){
    var isChecked = $('#'+id).prop("checked");
    // console.log(isChecked);
    var tempChurch = this.getChurch(id);
    tempChurch = this._libs.convert2ChurchModel(tempChurch);
    tempChurch['active'] = isChecked;
    this.churchService.updateChurch(id, tempChurch);
  }

  churchDelete(id, modalId){
    this.modalId = modalId;
    this.church = this.getChurch(id);
    this.isModal = this._libs.openModal(modalId);
  }

  searchChurch(e){
    // console.log(e);
    var text = e.toLowerCase();
    if (e.length > 0){
      var temp = [];
      for (var i = 0; i < this.churches.length; ++i) {
        var tempDevs = (this.churches[i]['name']).toLowerCase();
        if (tempDevs != undefined && tempDevs != ''){
          if (tempDevs.indexOf(e) >= 0){
            temp.push(this.churches[i]);
          }
        }
      }
      this.churches = temp;
    }else{
      this.churches = this._libs.getLocalStorageItem('churches');
    }
    this.setPage(this.currentPage);
  }

  confirmYes(){
    this.churchService.deleteChurch(this.key);
    this.getChurchesList();
    this.key = '';
    this.closeModal();
  }

  goBack(){
    this._libs.go2Page(this.backpage);
  }
  
  ///MODAL 
  closeModal(){
    this.modalId = '';
    this.isModal = this._libs.closeModal();
    this.church = new ChurchModel();
  }


  ///PAGE
  setPage(page: number) {
      // get pagerCarriers object from service
      this.pager = this.pagerService.getPager(this.churches.length, page, this.pageSize);
      // console.log(this.pagerCarriers);
      this.currentPage = page;
      // get current page of items
     this.pagedItems = this.churches.slice(this.pager.startIndex, this.pager.endIndex + 1);
      // console.log(this.pagedItems);
  }

}