<div class="row">
    <div class="col-md-12" *ngIf="openAddRole">
        <!-- BEGIN BORDERED TABLE PORTLET-->
        <div class="portlet light portlet-fit bordered">
            <div class="portlet-title">
                <div class="caption">
                    <span class="caption-subject font-dark bold uppercase">{{title}} Role {{role['name']}}</span>
                </div>
                <div class="actions">
                    <a class="btn dark btn-outline pull-right" href="javascript:;" (click)="addRole()"> 
                        <i class="fa fa-times"></i>
                    </a>
                </div>
            </div>
            <div class="portlet-body">
                <div class="table-scrollable">
                    <table class="table table-bordered table-hover">
                        <thead>
                            <tr>
                                <th style="vertical-align: top" class="uppercase" rowspan="2">Role Name <span class="font-red" *ngIf="!isValidName" >(*)</span></th>
                                <th class="text-center uppercase" [attr.colspan]="menusTotal">Set Permission </th>
                            </tr>
                            <tr>
                                <th class="col-md-1 col-xs-1 text-center" *ngFor="let menu of menus"> {{menu['name']}} </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <div class="form-group" [ngClass]="{'has-error': !isValidName}">
                                        <input type="text" class="form-control" placeholder="Role Name" id="name" name="name" [(ngModel)]="role['name']"> 
                                    </div> 
                                </td>
                                <td *ngFor="let menu of menus | slice:0:1; let i = index">
                                    <div class="mt-checkbox-list">
                                        <label class="mt-checkbox mt-checkbox-outline">
                                            <input type="checkbox" value="{{menu['id']}}" id="permission-{{i}}" name="permission-{{i}}" checked disabled>
                                            <span></span>
                                        </label>
                                    </div>
                                </td>
                                <td *ngFor="let menu of menus | slice:1:menusTotal; let i = index">
                                    <div class="mt-checkbox-list">
                                        <label class="mt-checkbox mt-checkbox-outline">
                                            <input type="checkbox" value="{{menu['id']}}" id="permission-{{i+1}}" name="permission-{{i+1}}" [checked]="isPermissionCheck(menu['id'])">
                                            <span></span>
                                        </label>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <hr>
                <div class="form-actions">
                    <button type="submit" class="btn blue" (click)="onAddSubmit()">{{btnText}}</button>
                    <button type="button" class="btn default" (click)="reset()">Cancel</button>
                </div>
            </div>
        </div>
        <!-- END BORDERED TABLE PORTLET-->
    </div>
    
    <div class="col-md-12">
        <!-- BEGIN BORDERED TABLE PORTLET-->
        <div class="portlet light portlet-fit bordered">
            <div class="portlet-title">
                <div class="caption">
                    <span class="caption-subject font-blue sbold uppercase">Roles Management</span>
                </div>
                <div class="actions">
                    <div class="btn-group btn-group-devided" data-toggle="buttons">
                        <label class="btn green btn-sm active btn-outline" (click)="addRole()">
                            <input type="radio" name="options" class="toggle" id="option1">Add Role</label>
                        <label class="btn grey-salsa btn-sm hide">
                            <input type="radio" name="options" class="toggle" id="option2">Settings</label>
                    </div>
                    <div class="btn-group hide">
                        <a class="btn dark btn-outline btn-circle btn-sm" href="javascript:;" data-toggle="dropdown" data-hover="dropdown" data-close-others="true"> Actions
                            <i class="fa fa-angle-down"></i>
                        </a>
                        <ul class="dropdown-menu pull-right">
                            <li>
                                <a href="javascript:;"> Option 1</a>
                            </li>
                            <li class="divider"> </li>
                            <li>
                                <a href="javascript:;">Option 2</a>
                            </li>
                            <li>
                                <a href="javascript:;">Option 3</a>
                            </li>
                            <li>
                                <a href="javascript:;">Option 4</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="portlet-body">
                <div class="table-scrollable table-scrollable-borderless">
                    <table class="table table-hover table-light">
                        <thead>
                            <tr class="uppercase">
                                <th> Role Name </th>
                                <th class="col-md-1 col-xs-1 text-center" *ngFor="let menu of menus"> {{menu['name']}} </th>
                                <th class="col-md-2 col-xs-2"> </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let role of roles">
                                <td> {{role['name']}} </td>
                                <td *ngFor="let menu of menus; let i = index" class="text-center"> 
                                    <div class="mt-checkbox-list">
                                        <label class="mt-checkbox mt-checkbox-outline">
                                            <input type="checkbox" value="{{menu['id']}}" id="menu-{{i+1}}" name="menu-{{i+1}}" disabled [checked]="isPermission(role['permission'], menu['id'])">
                                            <span></span>
                                        </label>
                                    </div>
                                </td>
                                <td class="text-right">
                                    <a (click)="roleEdit(role['id'])" class="btn dark btn-sm btn-outline sbold uppercase">
                                        <i class="fa fa-pencil"></i> 
                                    </a>
                                    <a (click)="roleDelete(role['id'], 'delete')" class="btn red btn-sm btn-outline sbold uppercase">
                                        <i class="fa fa-trash"></i> 
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <!-- END BORDERED TABLE PORTLET-->
    </div>
</div>


<div class="modal fade hide" id="delete" tabindex="-1" role="basic" aria-hidden="true" style="padding-right: 17px;" [ngStyle]="{'display':isModal}">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="closeModal()"></button>
                <h4 class="modal-title">Confirmation</h4>
            </div>
            <div class="modal-body"> Are you sure you want to delete Role: '{{role['name']}}' ? </div>
            <div class="modal-footer">
                <button type="button" class="btn red" (click)="confirmYes()" > Yes </button>
                <button type="button" class="btn dark btn-outline" data-dismiss="modal" (click)="closeModal()">No</button>
            </div>
        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>