 <!-- BEGIN PAGE BASE CONTENT -->
<div class="row">
    <div class="col-md-12">
        <!-- BEGIN PROFILE SIDEBAR -->
        <div class="profile-sidebar">
            <!-- PORTLET MAIN -->
            <div class="portlet light profile-sidebar-portlet bordered">
                <!-- SIDEBAR USERPIC -->
                <div class="profile-userpic text-center">
                    <img class="img-responsive base64image hide d-none" [src]="uploadUrl + currentUser['avatar']" />
                    <span class="c-initial-name" style="background-color: {{initialNameBG['bg']}}; color: {{initialNameBG['color']}}">{{initialName}}</span>
                </div>
                <!-- END SIDEBAR USERPIC -->
                <!-- SIDEBAR USER TITLE -->
                <div class="profile-usertitle">
                    <div class="profile-usertitle-name"> {{currentUser.firstname}} {{currentUser.lastname}} </div>
                    <div class="profile-usertitle-job"> {{currentUser.occupation}} </div>
                </div>
                <!-- END SIDEBAR USER TITLE -->
                <!-- SIDEBAR BUTTONS -->
                <div class="profile-userbuttons hide">
                    <button type="button" class="btn btn-circle green btn-sm">Follow</button>
                    <button type="button" class="btn btn-circle red btn-sm">Message</button>
                </div>
                <!-- END SIDEBAR BUTTONS -->
                <!-- SIDEBAR MENU -->
                <div class="profile-usermenu hide">
                    <ul class="nav">
                        <li>
                            <a href="page_user_profile_1.html">
                                <i class="icon-home"></i> Overview </a>
                        </li>
                        <li class="active">
                            <a href="page_user_profile_1_account.html">
                                <i class="icon-settings"></i> Account Settings </a>
                        </li>
                        <li>
                            <a href="page_user_profile_1_help.html">
                                <i class="icon-info"></i> Help </a>
                        </li>
                    </ul>
                </div>
                <!-- END MENU -->
            </div>
            <!-- END PORTLET MAIN -->
            <!-- PORTLET MAIN -->
            <div class="portlet light bordered hide">
                <!-- STAT -->
                <div class="row list-separated profile-stat">
                    <div class="col-md-4 col-sm-4 col-xs-6">
                        <div class="uppercase profile-stat-title"> 37 </div>
                        <div class="uppercase profile-stat-text"> Projects </div>
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-6">
                        <div class="uppercase profile-stat-title"> 51 </div>
                        <div class="uppercase profile-stat-text"> Tasks </div>
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-6">
                        <div class="uppercase profile-stat-title"> 61 </div>
                        <div class="uppercase profile-stat-text"> Uploads </div>
                    </div>
                </div>
                <!-- END STAT -->
                <div>
                    <h4 class="profile-desc-title">About Marcus Doe</h4>
                    <span class="profile-desc-text"> Lorem ipsum dolor sit amet diam nonummy nibh dolore. </span>
                    <div class="margin-top-20 profile-desc-link">
                        <i class="fa fa-globe"></i>
                        <a href="http://www.keenthemes.com">www.keenthemes.com</a>
                    </div>
                    <div class="margin-top-20 profile-desc-link">
                        <i class="fa fa-twitter"></i>
                        <a href="http://www.twitter.com/keenthemes/">@keenthemes</a>
                    </div>
                    <div class="margin-top-20 profile-desc-link">
                        <i class="fa fa-facebook"></i>
                        <a href="http://www.facebook.com/keenthemes/">keenthemes</a>
                    </div>
                </div>
            </div>
            <!-- END PORTLET MAIN -->
        </div>
        <!-- END BEGIN PROFILE SIDEBAR -->
        <!-- BEGIN PROFILE CONTENT -->
        <div class="profile-content">
            <div class="row">
                <div class="col-md-12">
                    <div class="portlet light bordered">
                        <div class="portlet-title tabbable-line">
                            <div class="caption caption-md">
                                <i class="icon-globe theme-font hide"></i>
                                <span class="caption-subject font-blue-madison bold uppercase">Profile Account</span>
                            </div>
                            <ul class="nav nav-tabs">
                                <li class="active">
                                    <a href="#tab_1_1" data-toggle="tab">Personal Info</a>
                                </li>
                                <li>
                                    <a href="#tab_1_2" data-toggle="tab">Change Password</a>
                                </li>
                            </ul>
                        </div>
                        <div class="portlet-body">
                            <div class="tab-content">
                                <!-- PERSONAL INFO TAB -->
                                <div class="tab-pane active" id="tab_1_1">
                                    <form role="form" (ngSubmit)="onSubmit()">
                                    	<div class="row">
		                    				<div class="col-md-4 col-xs-12">
		                                        <div class="form-group">
		                                            <label class="control-label">First Name <span class="font-red" *ngIf="!isValidFirstName" >(*)</span></label>
		                                            <input type="text" placeholder="First Name" class="form-control" name="firstname" id="firstname" [(ngModel)]="currentUser.firstname"/> 
		                                        </div>
		                                    </div>
		                    				<div class="col-md-4 col-xs-12">
		                                        <div class="form-group">
		                                            <label class="control-label">Last Name <span class="font-red" *ngIf="!isValidLastName" >(*)</span></label>
		                                            <input type="text" placeholder="Last Name" class="form-control" name="lastname" id="lastname" [(ngModel)]="currentUser.lastname" /> 
		                                        </div>
		                                    </div>
		                    				<div class="col-md-4 col-xs-12">
		                                        <div class="form-group">
		                                            <label class="control-label">Email</label>
		                                            <input type="text" placeholder="Email" disabled class="form-control" name="email" id="email" [(ngModel)]="currentUser.email" /> 
		                                        </div>
		                                    </div>
		                    				<div class="col-md-5 col-xs-12">
		                                        <div class="form-group">
		                                            <label class="control-label">Work Phone Number <span class="font-red" *ngIf="!isValidPhone" >(*)</span></label>
		                                            <input type="text" placeholder="Work Phone Number" class="form-control" name="phone" id="phone" [(ngModel)]="currentUser.phone" /> 
		                                        </div>
		                                    </div>
		                                    <div class="col-md-2 col-xs-12">
		                                        <div class="form-group">
		                                            <label class="control-label">Extention </label>
		                                            <input type="text" placeholder="Extention" class="form-control" name="ext" id="ext" [(ngModel)]="currentUser.ext" /> 
		                                        </div>
		                                    </div>
		                    				<div class="col-md-5 col-xs-12">		                                        
		                                        <div class="form-group">
		                                            <label class="control-label">Cell Phone Number</label>
		                                            <input type="text" placeholder="Cell Phone Number" class="form-control" name="cellphone" id="cellphone" [(ngModel)]="currentUser.cellphone" /> 
		                                        </div>
		                                    </div>
		                    				<div class="col-md-6 col-xs-12">
		                                        <div class="form-group">
		                                            <label class="control-label">Occupation <span class="font-red" *ngIf="!isValidOccupation" >(*)</span></label>
		                                            <input type="text" placeholder="Occupation" class="form-control" name="occupation" id="occupation" [(ngModel)]="currentUser.occupation" /> 
		                                        </div>
		                                    </div>
		                    				<div class="col-md-12 col-xs-12">
		                                        <div class="form-group">
		                                            <label class="control-label">About</label>
		                                            <textarea class="form-control" rows="3" placeholder="About" name="about" id="about" [(ngModel)]="currentUser.about"></textarea>
		                                        </div>
		                                    </div>
		                                    <div class="col-md-6 col-xs-12 d-none hide">
						                        <div class="form-group">
						                            <label for="logo" style="margin-right: 5px;">Avatar </label>
				                                    <button type="button" class="btn blue" (click)="openModal('listMedias')">Open Media Library</button>
						                        </div>
						                    </div>
						                    <div class="col-md-6 col-xs-12 right d-none hide">
												<img class="img-responsive" style="max-width: 150px;" id='base64image' [src]="uploadUrl + currentUser['avatar']"/>
											</div>
		                                </div>
		                                <hr>
                                        <div class="margiv-top-10">
                                            <button type="submit" class="btn green">Save Changes </button>
                                            <button type="button" (click)="cancel()" class="btn default"> Cancel </button>
                                        </div>
                                    </form>
                                </div>
                                <!-- END PERSONAL INFO TAB -->
                                <!-- CHANGE PASSWORD TAB -->
                                <div class="tab-pane" id="tab_1_2">
                                    <form action="#">
                                    	<div class="row">
                                    		<div class="col-md-12 col-xs-12 c-padding-b-20" *ngIf="errorMessage != ''">
                								<div class="font-red">{{errorMessage}}</div>
                							</div>
	                                        <div class="col-md-4 col-xs-12">
		                                        <div class="form-group">
		                                            <label class="control-label">Current Password <span class="font-red" *ngIf="!isValidVideoCurrentPass" >(*)</span></label>
		                                            <input type="password" class="form-control" id="current-password" name="current-password"/> 
		                                        </div>
	                                        </div>
	                                        <div class="col-md-4 col-xs-12">
		                                        <div class="form-group">
		                                            <label class="control-label">New Password <span class="font-red" *ngIf="!isValidVideoNewPass" >(*)</span></label>
		                                            <input type="password" class="form-control" id="new-password" name="new-password"/> 
		                                        </div>
		                                    </div>
	                                        <div class="col-md-4 col-xs-12">
		                                        <div class="form-group">
		                                            <label class="control-label">Re-type New Password <span class="font-red" *ngIf="!isValidVideoRetypeNewPass" >(*)</span></label>
		                                            <input type="password" class="form-control" id="retype-new-password" name="retype-new-password"/> 
		                                        </div>
		                                    </div>
		                                </div>
	                                    <hr>
                                        <div class="margin-top-10">
                                            <a href="javascript:;" class="btn green" (click)="onSubmitUpdatePassword()"> Change Password </a>
                                            <a href="javascript:;" class="btn default" routerLink="/profile"> Cancel </a>
                                        </div>
                                    </form>
                                </div>
                                <!-- END CHANGE PASSWORD TAB -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- END PROFILE CONTENT -->
    </div>
</div>
<!-- END PAGE BASE CONTENT -->

<!-- MODAL MEDIA -->
<div class="modal fade hide" id="listMedias" tabindex="-1" role="basic" aria-hidden="true" style="padding-right: 17px;" [ngStyle]="{'display': isModal}" > <!-- [ngClass]="{'hide': modalId != 'listMedia'}" -->
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <div class="pull-right">
                    <a class="btn blue btn-outline pull-left" href="javascript:;" (click)="addMediaModal('addMedia')"> 
                        Add Media
                    </a>
                    <a class="btn dark btn-outline pull-right" href="javascript:;" (click)="closeModal()"> 
                        <i class="fa fa-times"></i>
                    </a>
                </div>
                <h4 class="modal-title">Library Media {{currentUser['avatar']}}</h4>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-3 col-sm-6 col-xs-12 c-margin-b-15" *ngFor="let media of medias; let i = index" [attr.data-index]="i">
                        <div class="mt-widget-1">
                            <div class="mt-icon">
                                <label class="mt-checkbox mt-checkbox-outline">
                                    <input type="checkbox" name="listmedia" id="media-{{media['id']}}" [value]="media['id']" (change)="chooseAvatar(media['name'])" [checked]="selectedAvatar(media['id'])" > <!-- [(ngModel)]="user['avatar']" -->
                                    <span></span>
                                </label>
                            </div>
                            <div class="mt-img">
                                <img [src]="uploadUrl + (media['name'])" class="img-responsive" (click)="chooseAvatar(media['name'])" > 
                            </div>
                            <div class="mt-body hide">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn dark btn-outline" (click)="closeModal()">
                    <span> Close</span>
                </button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade hide" id="addMedia" tabindex="-1" role="basic" aria-hidden="true" style="padding-right: 17px;" [ngStyle]="{'display': isModal}"> <!-- [ngClass]="{'hide': modalId != 'addMedia'}" -->
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <div class="pull-right">
                	<label class="c-margin-l-r-5">
	                    <span class="btn green fileinput-button pull-left">
	                        <i class="fa fa-plus"></i>
	                        <span> Add files... </span>
	                        <input accept="image/*" id="file-upload" multiple (change)="onUpload($event)" type="file" #mediaFileInput /> 
	                    </span>
	                </label>
                    <a class="btn dark btn-outline pull-right" href="javascript:;" (click)="closeModal()"> 
                        <i class="fa fa-times"></i>
                    </a>
                </div>
                <h4 class="modal-title">Add Media</h4>
            </div>
            <div class="modal-body" *ngIf="filesUpload.length > 0">
                <div class="row">
                    <div class="col-xs-12 font-red text-center c-padding-b-20" *ngIf="requireMessage != '' || result != ''">
                        {{requireMessage}} {{result}}
                    </div>
                    <div class="col-md-12 col-xs-12">
                        <table role="presentation" class="table table-striped clearfix">
                            <thead>
                                <tr>
                                    <th style="max-width: 80px;" class="col-md-1 col-xs-2"></th>
                                    <th class="col-md-6 col-xs-4">Name</th>
                                    <th class="col-md-2 hidden-xs">Size</th>
                                    <th class="col-md-2 col-xs-4">Progress</th>
                                    <th class="col-md-1 col-xs-2"></th>
                                </tr>
                            </thead>
                            <tbody class="files"> 
                                <tr class="template-upload fade in" *ngFor="let file of filesUpload; let i = index">
                                    <td>
                                        <span class="preview">
                                            <img class="img-responsive" style="max-height: 60px;" id='base64image' [src]="sanitizerUrl(file.imgCode)"/>
                                        </span>
                                    </td>
                                    <td>
                                        <p class="name">{{file.name}}</p>
                                        <strong class="error text-danger label label-danger"></strong>
                                    </td>
                                    <td>
                                        <p class="size">{{convertSize(file.size)}}</p>
                                    </td>
                                    <td>
                                        <div class="progress progress-striped active" role="progressbar" aria-valuemin="0" aria-valuemax="100" aria-valuenow="0">
                                            <div class="progress-bar progress-bar-success" style="width:0%;"></div>
                                        </div>
                                    </td>
                                    <td> 
                                        <button class="btn blue start hide">
                                            <i class="fa fa-upload"></i>
                                            <span>Start</span>
                                        </button>  
                                        <button class="btn red cancel" (click)="removeFile(file['name'])" >
                                            <i class="fa fa-trash"></i>
                                        </button>  
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn blue start" [disabled]="!isLoaded" (click)="uploadAll()">
                    <i class="fa fa-upload"></i>
                    <span> Start upload </span>
                </button>
                <button type="reset" class="btn warning cancel" [disabled]="!isLoaded" (click)="closeModal()">
                    <i class="fa fa-ban-circle"></i>
                    <span> Cancel upload </span>
                </button>
                <button type="button" class="btn red delete" [disabled]="!isLoaded" (click)="removeAllFiles()">
                    <i class="fa fa-trash"></i>
                    <span> Delete All</span>
                </button>
            </div>
        </div>
    </div>
</div>