import { Injectable } from '@angular/core';
import * as $ from 'jquery';

@Injectable()
export class LocalData {
  logo = '../assets/imgs/logos/logo.png'
  wlogo = '../assets/imgs/logos/logo-w.png'
  sitename = 'Sunday Masses';
  website = 'https://www.lechuanhat.com';
  uploadUrl = 'http://upload.doleconnections.com/';

  timeZone = [
    { id: 1, name: 'Pacific Time', code: 'PDT' },
    { id: 2, name: 'Mountain Time', code: 'MDT' },
    { id: 3, name: 'Central Time', code: 'CDT' },
    { id: 4, name: 'Eastern Time', code: 'EDT' },
    { id: 5, name: 'Indochina Time', code: 'GTM+7' },
    { id: 6, name: 'China Standard Time', code: 'GTM+8' },
    { id: 7, name: 'Central European Summer Time', code: 'GTM+2' },
  ]

  bgColor = [
  	{
  		bg: '#000000',
  		color: '#fff'
  	},
  	{
  		bg: '#EEEEEE',
  		color: '#fff'
  	},
  	{
  		bg: '#32C5D2',
  		color: '#fff'
  	},
  	{
  		bg: '#EB5D68',
  		color: '#fff'
  	},
	{
  		bg: '#FF6B57',
  		color: '#fff'
  	},
  	{
  		bg: '#3498DB',
  		color: '#fff'
  	},
  	{
  		bg: '#B771B0',
  		color: '#fff'
  	},
  	{
  		bg: '#8A7F68',
  		color: '#fff'
  	},
  	{
  		bg: '#2F353B',
  		color: '#fff'
  	},
  	{
  		bg: '#F7F7F7',
  		color: '#000'
  	},
  	{
  		bg: '#52C9BA',
  		color: '#fff'
  	},
  	{
  		bg: '#CD6262',
  		color: '#fff'
  	},
	{
  		bg: '#C8D046',
  		color: '#fff'
  	},
  	{
  		bg: '#5F9CD1',
  		color: '#fff'
  	},
  	{
  		bg: '#A962BB',
  		color: '#fff'
  	},
  	{
  		bg: '#685E47',
  		color: '#fff'
  	},
  	{
  		bg: '#525E64',
  		color: '#fff'
  	},
  	{
  		bg: '#677083',
  		color: '#fff'
  	},
  	{
  		bg: '#5DC09C',
  		color: '#fff'
  	},
  	{
  		bg: '#E7505A',
  		color: '#fff'
  	},
	{
  		bg: '#C5BF66',
  		color: '#fff'
  	},
  	{
  		bg: '#5893DD',
  		color: '#fff'
  	},
  	{
  		bg: '#AA67A3',
  		color: '#fff'
  	},
  	{
  		bg: '#7A6A61',
  		color: '#fff'
  	},
  	{
  		bg: '#31383C',
  		color: '#fff'
  	},
  	{
  		bg: '#7F8C97',
  		color: '#fff'
  	},
  	{
  		bg: '#84C68F',
  		color: '#fff'
  	},
  	{
  		bg: '#D05163',
  		color: '#fff'
  	},
	{
  		bg: '#C5B96B',
  		color: '#fff'
  	},
  	{
  		bg: '#57BFE1',
  		color: '#fff'
  	},
  	{
  		bg: '#AC3773',
  		color: '#fff'
  	},
  	{
  		bg: '#9D8B81',
  		color: '#fff'
  	},
  	{
  		bg: '#41515B',
  		color: '#fff'
  	},
  ]

  states = [
            { id: '1', name: 'Alabama', code: 'AL'},
            { id: '2', name: 'Alaska', code: 'AK'},
            { id: '3', name: 'Arizona', code: 'AZ'},
            { id: '4', name: 'Arkansas', code: 'AR'},
            { id: '5', name: 'California', code: 'CA'},
            { id: '6', name: 'Colorado', code: 'CO'},
            { id: '7', name: 'Connecticut', code: 'CT'},
            { id: '8', name: 'Delaware', code: 'DE'},
            { id: '9', name: 'Florida', code: 'FL'},
            { id: '10', name: 'Georgia', code: 'GA'},
            { id: '11', name: 'Hawaii', code: 'HI'},
            { id: '12', name: 'Idaho', code: 'ID'},
            { id: '13', name: 'Illinois', code: 'IL'},
            { id: '14', name: 'Indiana', code: 'IN'},
            { id: '15', name: 'Iowa', code: 'IA'},
            { id: '16', name: 'Kansas', code: 'KS'},
            { id: '17', name: 'Kentucky', code: 'KY'},
            { id: '18', name: 'Louisiana', code: 'LA'},
            { id: '19', name: 'Maine', code: 'ME'},
            { id: '20', name: 'Maryland', code: 'MD'},
            { id: '21', name: 'Massachusetts', code: 'MA'},
            { id: '22', name: 'Michigan', code: 'MI'},
            { id: '23', name: 'Minnesota', code: 'MN'},
            { id: '24', name: 'Mississippi', code: 'MS'},
            { id: '25', name: 'Missouri', code: 'MO'},
            { id: '26', name: 'Montana', code: 'MT'},
            { id: '27', name: 'Nebraska', code: 'NE'},
            { id: '28', name: 'Nevada', code: 'NV'},
            { id: '29', name: 'New Hampshire', code: 'NH'},
            { id: '30', name: 'New Jersey', code: 'NJ'},
            { id: '31', name: 'New Mexico', code: 'NM'},
            { id: '32', name: 'New York', code: 'NY'},
            { id: '33', name: 'North Carolina', code: 'NC'},
            { id: '34', name: 'North Dakota', code: 'ND'},
            { id: '35', name: 'Ohio', code: 'OH'},
            { id: '36', name: 'Oklahoma', code: 'OK'},
            { id: '37', name: 'Oregon', code: 'OR'},
            { id: '38', name: 'Pennsylvania', code: 'PA'},
            { id: '39', name: 'Rhode Island', code: 'RI'},
            { id: '40', name: 'South Carolina', code: 'SC'},
            { id: '41', name: 'South Dakota', code: 'SD'},
            { id: '42', name: 'Tennessee', code: 'TN'},
            { id: '43', name: 'Texas', code: 'TX'},
            { id: '44', name: 'Utah', code: 'UT'},
            { id: '45', name: 'Vermont', code: 'VT'},
            { id: '46', name: 'Virginia', code: 'VA'},
            { id: '47', name: 'Washington', code: 'WA'},
            { id: '48', name: 'West Virginia', code: 'WV'},
            { id: '49', name: 'Wisconsin', code: 'WI'},
            { id: '50', name: 'Wyoming', code: 'WY'},
            { id: '51', name: 'District of Columbia', code: 'DC'}
           ]
}


 //  	{
 //  		bg: '#EEEEEE',
 //  		color: '#fff'
 //  	},
 //  	{
 //  		bg: '#32C5D2',
 //  		color: '#fff'
 //  	},
 //  	{
 //  		bg: '#EB5D68',
 //  		color: '#fff'
 //  	},
	// {
 //  		bg: '#C8D046',
 //  		color: '#fff'
 //  	},
 //  	{
 //  		bg: '#3498DB',
 //  		color: '#fff'
 //  	},
 //  	{
 //  		bg: '#B771B0',
 //  		color: '#fff'
 //  	},
 //  	{
 //  		bg: '#8A7F68',
 //  		color: '#fff'
 //  	},
 //  	{
 //  		bg: '#2F353B',
 //  		color: '#fff'
 //  	},