<div class="row">
    <div class="col-md-6 col-xs-6">
        <div class="portlet light bordered">
            <div class="portlet-title">
                <div class="caption font-dark">
                    <i class="icon-settings font-dark hide"></i>
                    <span class="caption-subject bold uppercase">{{title}} Mass</span>
                </div>
            </div>
            <div class="portlet-body form">
                <form role="form" [formGroup]="form" (ngSubmit)="onSubmit()">
                    <div class="form-body">
                        <div class="row">
                            <div class="col-md-12 col-xs-12">
                                <div class="form-group required">
                                    <label>Mass's Name</label>
                                    <input type="text" class="form-control" placeholder="Mass Name" name="name" id="name" formControlName="name" [(ngModel)]="mass.name">
                                </div>
                            </div>
                            <div class="col-md-6 col-xs-12">
                                <div class="form-group required">
                                    <label>At Church</label>
                                    <select class="form-control c-square" id="churchId" name="churchId" [(ngModel)]="mass['churchId']" formControlName="churchId">
                                        <option value="">Select Church</option>
                                        <option *ngFor="let church of churches" [value]="church.id">{{church.name}} - {{church.city}} {{church.state}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-2 col-xs-12">
                                <div class="form-group">
                                    <label>Live Stream</label>
                                    <div class="mt-checkbox-list">
                                        <label class="mt-checkbox mt-checkbox-outline">
                                            <input type="checkbox" id="live" name="live" class="c-check" formControlName="live" [(ngModel)]="mass.live" >
                                            <span></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 col-xs-12">
                                <div class="form-group required">
                                    <label>Video Type</label>
                                    <select class="form-control c-square" id="videotype" name="videotype" formControlName="videotype" [(ngModel)]="mass.videotype" (change)="selectVideoType($event)">
                                        <option value="">Select Video Type</option>
                                        <option *ngFor="let vt of videoTypes" [value]="vt.id">{{vt.name}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-6 col-xs-12">
                                <div class="form-group required">
                                    <label>Video {{videoType['name']}} Code</label>
                                    <input type="text" class="form-control" placeholder="Video {{videoType['name']}} Code" name="link" id="link" formControlName="link" [(ngModel)]="mass.link"> 
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 col-xs-12">
                                <div class="form-group required">
                                    <label>Mass's Date</label>
                                    <input type="text" class="form-control date-format" placeholder="MM/dd/yyyy" name="date" id="date" formControlName="date" [(ngModel)]="mass.date"> 
                                </div>
                            </div>
                            <div class="col-md-4 col-xs-12">
                                <div class="form-group required">
                                    <label>Mass's Time</label>
                                    <input type="text" class="form-control time-format" placeholder="hh:mm (24 Hours)" name="time" id="time" formControlName="time" [(ngModel)]="mass.time"> 
                                </div>
                            </div>
                            <div class="col-md-4 col-xs-12">
                                <div class="form-group required">
                                    <label>Timezone</label>
                                    <select class="form-control c-square" id="timezone" name="timezone" [(ngModel)]="mass['timezone']" formControlName="timezone">
                                        <option value="">Select Timezone</option>
                                        <option *ngFor="let tz of timeZone" [value]="tz.code">{{tz.name}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-actions">
                        <button type="submit" class="btn blue">{{btnText}}</button>
                        <button type="button" class="btn default" (click)="reset()">Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <!-- LIST ALL CHURCHES -->
    <div class="col-md-6 col-sm-6">
        <div class="portlet light portlet-fit bordered">
            <div class="portlet-title">
                <div class="caption">
                    <i class="icon-microphone font-dark hide"></i>
                    <span class="caption-subject bold font-dark uppercase">All Masses</span>
                </div>
                <div class="actions">
                    <div class="btn-group btn-group-devided hide" data-toggle="buttons">
                        <label class="btn red btn-outline btn-circle btn-sm active">
                            <input type="radio" name="options" class="toggle" id="option1">Settings</label>
                        <label class="btn  red btn-outline btn-circle btn-sm">
                            <input type="radio" name="options" class="toggle" id="option2">Tools</label>
                    </div>
                    <div class="input-icon right c-margin-l-r-5" style="float: right;">
                        <i class="icon-magnifier"></i>
                        <input type="text" class="form-control form-control-solid" placeholder="Search by Name..." (input)="searchMass($event.target.value)"> 
                    </div>
                </div>
            </div>
            <div class="portlet-body">
                <div class="table-scrollable">
                    <table class="table table-striped table-bordered table-advance table-hover">
                        <thead>
                            <tr>
                                <th class="col-md-5 col-xs-2"> Name </th>
                                <th class="col-md-5 col-xs-2"> Address </th>
                                <th class="col-md-2 col-xs-1">  </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let mass of pagedItems ">
                                <td class="">
                                    {{mass['name']}}
                                    <p class="small col-12 c-no-margin-b">Date: {{mass['date']}} - {{mass['time']}} ({{mass['timezone']}})</p>
                                </td>
                                <td>{{mass.church['name']}} - {{mass.church['city']}}, {{mass.church['state']}}</td>
                                <td>
                                    <a (click)="massEdit(mass.id)" class="btn dark btn-sm btn-outline sbold uppercase">
                                        <i class="fa fa-pencil"></i>  
                                    </a>
                                    <a (click)="massDelete(mass.id, 'delete')" class="btn red btn-sm btn-outline sbold uppercase">
                                        <i class="fa fa-trash"></i>  
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="row">
                    <div class="col-md-12 col-xs-12 text-right c-padding-b-20">
                        <!-- BEGIN: PAGE CONTENT -->
                        <ul *ngIf="pager.pages && pager.pages.length" class="pagination bootpag">
                            <li [ngClass]="{disabled:pager.currentPage === 1}">
                                <a (click)="setPage(1)"><i class="fa fa-angle-double-left"></i></a>
                            </li>
                            <li [ngClass]="{disabled:pager.currentPage === 1}">
                                <a (click)="setPage(pager.currentPage - 1)"><i class="fa fa-angle-left"></i></a>
                            </li>
                            <li *ngFor="let page of pager.pages" [ngClass]="{'c-active': pager.currentPage === page}">
                                <a (click)="setPage(page)">{{page}}</a>
                            </li>
                            <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                                <a (click)="setPage(pager.currentPage + 1)"><i class="fa fa-angle-right"></i></a>
                            </li>
                            <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                                <a (click)="setPage(pager.totalPages)"><i class="fa fa-angle-double-right"></i></a>
                            </li>
                        </ul>
                    <!-- END: PAGE CONTENT -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade hide bs-modal-lg" id="view" tabindex="-1" role="dialog" aria-hidden="true" [ngStyle]="{'display': isModal}" style="padding-right: 17px;">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="closeModal()" ></button>
                <h4 class="modal-title">{{mass.name}}</h4>
            </div>
            <div class="modal-body">
                <dl class="dl-horizontal">
                    <dt>Address</dt>
                    <dd *ngIf="mass['address'] != ''">{{mass['address']}}, {{mass['city']}} {{mass['state']}} {{mass['zipcode']}}</dd>
                    <dt>Description</dt>
                    <dd>{{mass['description']}}</dd>
                </dl>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn dark btn-outline" data-dismiss="modal" (click)="closeModal()" >Close</button>
            </div>
        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>

<div class="modal fade hide" id="delete" tabindex="-1" role="basic" aria-hidden="true" style="padding-right: 17px;" [ngStyle]="{'display': isModal}">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="closeModal()"></button>
                <h4 class="modal-title">Confirmation</h4>
            </div>
            <div class="modal-body"> Are you sure you want to delete currentPage '{{mass['name']}}'' ? </div>
            <div class="modal-footer">
                <button type="button" class="btn red" (click)="confirmYes()" > Yes </button>
                <button type="button" class="btn dark btn-outline" data-dismiss="modal" (click)="closeModal()">No</button>
            </div>
        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>
