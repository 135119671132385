<div class="row">
	<div class="col-md-5 col-xs-12">

		<div class="portlet light bordered">
            <div class="portlet-title">
                <div class="caption font-dark">
                    <span class="caption-subject bold uppercase">{{title}} Menu</span>
                </div>
                <div class="tools">
                    <a [ngClass]="isExpandMenu ? 'collapsed' : 'expanded' " (click)="expandMenuBox()"> </a> <!-- collapse -->
                </div>
            </div>
            <div class="portlet-body form" [ngStyle]="{'display': isShowMenu}">
                <form role="form" [formGroup]="menuForm" (ngSubmit)="onSubmit()">
                    <div class="form-body">
	                    <div class="row">
	                    	<div class="col-md-12 col-sm-6 col-xs-12">
		                    	<div class="form-group">
			                        <label>Name <span class="font-red" *ngIf="!isValidName" >(*)</span></label>
			                        <input type="text" class="form-control" placeholder="Menu Name" name="name" id="name" formControlName="name" [(ngModel)]="menu.name"> 
			                    </div>
	                    	</div>
	                    	<div class="col-md-12 col-sm-6 col-xs-12">
			                    <div class="form-group">
			                        <label>Icon <span class="font-red" *ngIf="!isValidIcon" >(*)</span></label>
			                        <input type="text" class="form-control" placeholder="Menu Icon" name="icon" id="icon" formControlName="icon" [(ngModel)]="menu.icon"> 
			                    </div>
	                    	</div>
                            <div class="col-md-12 col-xs-12">
                                <div class="form-group">
                                    <label>Has Submenu </label>
                                    <div class="mt-checkbox-list">
                                        <label class="mt-checkbox mt-checkbox-outline"> If menu has submenu, please check it.
                                            <input type="checkbox" name="submenu" id="submenu" formControlName="submenu" [(ngModel)]="menu.submenu" (change)="hasSubmenu($event)"/>
                                            <span></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
	                    	<div class="col-md-12 col-xs-12" [ngClass]="{ 'hide' : isValidHasChild }">
		                    	<div class="form-group" [ngClass]="{'has-error': !isValidLink}">
			                        <label>Link <span class="font-red" *ngIf="!isValidLink" >(*)</span></label>
			                        <input type="text" class="form-control" placeholder="Menu Link" name="link" id="link" formControlName="link" [(ngModel)]="menu.link" [ngClass]="{ 'disabled' : !isValidLink }" > 
			                    </div>
	                    	</div>
                            <div class="col-md-12 col-xs-12">
                                <div class="form-group" [ngClass]="{'has-error': !isValidOrder}">
                                    <label>Order <span class="font-red" *ngIf="!isValidOrder" >(*)</span></label>
                                    <input type="text" class="form-control" placeholder="Menu Order" name="order" id="order" formControlName="order" [(ngModel)]="menu.order"> 
                                </div>
                            </div>
			            </div>
                    </div>
                    <div class="form-actions">
                        <button type="submit" class="btn blue">{{btnText}}</button>
                        <button type="button" class="btn default" (click)="resetMenu()">Cancel</button>
                    </div>
                </form>
            </div>
        </div>

        <div class="portlet light bordered">
            <div class="portlet-title">
                <div class="caption font-dark">
                    <span class="caption-subject bold uppercase">{{titleSub}} Sub Menu</span>
                </div>
                <div class="tools">
                    <a [ngClass]="isExpandSubmenu ? 'collapsed' : 'expanded' " data-original-title=""  (click)="expandSubmenuBox()"> </a> <!-- collapse -->
                </div>
            </div>
            <div class="portlet-body form" [ngStyle]="{'display': isShowSubmenu}">
                <form role="form" [formGroup]="submenuForm" (ngSubmit)="onSubmitSubmenu()">
                    <div class="form-body">
	                    <div class="row">
                            <div class="col-md-12 col-sm-6 col-xs-12 font-red c-padding-b-15" *ngIf="errorMessageSub != '' ">{{errorMessageSub}}</div>
                            <div class="col-md-12 col-xs-12">
                                <div class="form-group">
                                    <label>Menu <span class="font-red" *ngIf="!isValidMenu" >(*)</span></label>
                                    <select class="form-control" formControlName="menuId" name="menuId" id='menuId' [(ngModel)]="submenu['menuId']">
                                        <option value="">Choose One</option>
                                        <option value="{{menu['id']}}" *ngFor="let menu of menus">{{menu['name']}}</option>
                                    </select>
                                </div>
                            </div>
	                    	<div class="col-md-12 col-xs-12">
		                    	<div class="form-group">
			                        <label>Name <span class="font-red" *ngIf="!isValidSubmenuName" >(*)</span></label>
			                        <input type="text" class="form-control" placeholder="Menu Name" name="name" id="name" required formControlName="name"  minlength="4" [(ngModel)]="submenu.name"> 
			                    </div>
	                    	</div>
	                    	<div class="col-md-12 col-xs-12">
			                    <div class="form-group">
			                        <label>Icon <span class="font-red" *ngIf="!isValidSubmenuIcon" >(*)</span></label>
			                        <input type="text" class="form-control" placeholder="Submenu Icon" name="icon" id="icon" formControlName="icon" [(ngModel)]="submenu.icon"> 
			                    </div>
	                    	</div>
	                    	<div class="col-md-12 col-xs-12">
                                <div class="form-group">
                                    <label>Has Submenu Level 2</label>
                                    <div class="mt-checkbox-list">
                                        <label class="mt-checkbox mt-checkbox-outline"> If submenu has submenu level 2, please check it.
                                            <input type="checkbox" name="submenuL2" id="submenuL2" formControlName="submenuL2" [(ngModel)]="submenu.submenuL2" (change)="hasSubmenuL2($event)"/>
                                            <span></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
	                    	<div class="col-md-12 col-xs-12" [ngClass]="{ 'hide' : isValidHasChildL2 }">
		                    	<div class="form-group" [ngClass]="{'has-error': !isValidSubmenuLink}">
			                        <label>Link <span class="font-red" *ngIf="!isValidSubmenuLink" >(*)</span></label>
			                        <input type="text" class="form-control" placeholder="Submenu Link" name="link" id="link" formControlName="link" [(ngModel)]="submenu.link"> 
			                    </div>
	                    	</div>
                            <div class="col-md-12 col-xs-12">
                                <div class="form-group" [ngClass]="{'has-error': !isValidSubmenuOrder}">
                                    <label>Order <span class="font-red" *ngIf="!isValidSubmenuOrder" >(*)</span></label>
                                    <input type="text" class="form-control" placeholder="Submenu Order" name="order" id="order" formControlName="order" [(ngModel)]="submenu.order"> 
                                </div>
                            </div>
			            </div>
                    </div>
                    <div class="form-actions">
                        <button type="submit" class="btn blue">{{btnTextSub}}</button>
                        <button type="button" class="btn default" (click)="resetSubmenu()">Cancel</button>
                    </div>
                </form>
            </div>
        </div>

        <div class="portlet light bordered">
            <div class="portlet-title">
                <div class="caption font-dark">
                    <span class="caption-subject bold uppercase">{{titleSubL2}} Sub Menu Level 2</span>
                </div>
                <div class="tools">
                    <a [ngClass]="isExpandSubmenuL2 ? 'collapsed' : 'expanded' " data-original-title=""  (click)="expandSubmenuL2Box()"> </a> <!-- collapse -->
                </div>
            </div>
            <div class="portlet-body form" [ngStyle]="{'display': isShowSubmenuL2}">
                <form role="form" [formGroup]="submenuL2Form" (ngSubmit)="onSubmitSubmenuL2()">
                    <div class="form-body">
	                    <div class="row">
                            <div class="col-md-12 col-xs-12">
                                <div class="form-group">
                                    <label>Sub Menu <span class="font-red" *ngIf="!isValidSubMenu" >(*)</span></label>
                                    <select class="form-control" formControlName="menuId" name="menuId" id='menuId' [(ngModel)]="submenuL2['menuId']">
                                        <option value="">Choose One</option>
                                        <option value="{{submenu['id']}}" *ngFor="let submenu of submenus">{{submenu['name']}}</option>
                                    </select>
                                </div>
                            </div>
	                    	<div class="col-md-12 col-xs-12">
		                    	<div class="form-group">
			                        <label>Name <span class="font-red" *ngIf="!isValidSubmenuL2Name" >(*)</span></label>
			                        <input type="text" class="form-control" placeholder="Menu Name" name="name" id="name" required formControlName="name"  minlength="4" [(ngModel)]="submenuL2.name"> 
			                    </div>
	                    	</div>
	                    	<div class="col-md-12 col-xs-12">
			                    <div class="form-group">
			                        <label>Icon <span class="font-red" *ngIf="!isValidSubmenuL2Icon" >(*)</span></label>
			                        <input type="text" class="form-control" placeholder="Submenu Icon" name="icon" id="icon" formControlName="icon" [(ngModel)]="submenuL2.icon"> 
			                    </div>
	                    	</div>
	                    	<div class="col-md-12 col-xs-12">
		                    	<div class="form-group">
			                        <label>Link <span class="font-red" *ngIf="!isValidSubmenuL2Link" >(*)</span></label>
			                        <input type="text" class="form-control" placeholder="Submenu Link" name="link" id="link" formControlName="link" [(ngModel)]="submenuL2.link"> 
			                    </div>
	                    	</div>
			            </div>
                    </div>
                    <div class="form-actions">
                        <button type="submit" class="btn blue">{{btnTextSub}}</button>
                        <button type="button" class="btn default" (click)="resetSubmenuL2()">Cancel</button>
                    </div>
                </form>
            </div>
        </div>
	</div>

	<!-- LIST ALL DEVELOPERS -->
	<div class="col-md-7 col-sm-12">
        <div class="portlet light portlet-fit bordered show-all-list">
            <div class="portlet-title">
                <div class="caption">
                    <i class="icon-microphone font-dark hide"></i>
                    <span class="caption-subject bold font-dark uppercase">Menu / Sub Menu</span>
                </div>
                <div class="actions hide">
                    <div class="btn-group btn-group-devided" data-toggle="buttons">
                        <label class="btn red btn-outline btn-circle btn-sm active">
                            <input type="radio" name="options" class="toggle" id="option1">Settings</label>
                        <label class="btn  red btn-outline btn-circle btn-sm">
                            <input type="radio" name="options" class="toggle" id="option2">Tools</label>
                    </div>
                </div>
            </div>
            <div class="portlet-body">
        		<div class="row"> 
                    <div class="menu-div border-bottom">
                        <div class="col-md-6 col-xs-6 bold">Menu Name</div>
                        <div class="col-md-2 col-xs-2 bold text-left">Order</div>
                        <div class="col-md-4 col-xs-4 bold text-right">Action</div>
                    </div>
                </div>

                <div class="row menu-row" *ngFor="let menu of menus">
                    <div class="menu menu-div border-bottom">
                        <div class="col-md-6 col-xs-6 "> 
                            <div class="root-title">
                                <span>{{menu['name']}}</span>
                            </div>
                        </div>
                        <div class="col-md-2 col-xs-2 text-left">
                            {{menu['order']}}
                        </div>
                        <div class="col-md-4 col-xs-4 text-right">
                            <button class="btn dark btn-outline btn-sm" title="Edit" (click)="editMenu(menu['id'])"> 
                                <i class="fa fa-pencil"></i>
                            </button>
                            <button class="btn red btn-outline btn-sm" title="Delete" (click)="deteleMenu(menu['id'])"> 
                                    <i class="fa fa-trash"></i>
                                </button>
                        </div>
                    </div>
                    <div class="submenu" *ngFor="let submenu of getSubMenusById(menu['id']); let os = index">
                        <div class="menu-div border-bottom">
                            <div class="col-md-6 col-xs-6">
                                <div class="title">
                                    <span>{{submenu['name']}}</span>
                                </div>
                            </div>
                            <div class="col-md-2 col-xs-2 text-center">
                                {{submenu['order']}}
                            </div>
                            <div class="col-md-4 col-xs-4 text-right">
                                <button class="btn dark btn-outline btn-sm" title="Edit" (click)="editSubmenu(submenu['id'])"> 
                                    <i class="fa fa-pencil"></i>
                                </button>
                                <button class="btn red btn-outline btn-sm" title="Delete" (click)="deteleSubmenu(submenu['id'])"> 
                                    <i class="fa fa-trash"></i>
                                </button>
                            </div>
                        </div>
                        <div class="menu-div submenu-level2 border-bottom" *ngFor="let submenul2 of getSubMenusL2ById(submenu['id'])">
                            <div class="col-md-6 col-xs-8">
                                <div class="title">
                                    <span>{{submenul2['name']}}</span>
                                </div>
                            </div>
                            <div class="col-md-6 col-xs-4 text-right">
                                <button class="btn dark btn-outline btn-sm" title="Edit" (click)="editSubmenuL2(submenul2['id'])"> 
                                    <i class="fa fa-pencil"></i>
                                </button>
                                <button class="btn red btn-outline btn-sm" title="Delete" (click)="deteleSubmenu(submenul2['id'])"> 
                                    <i class="fa fa-trash"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row hide" *ngIf="isSubmenuLoaded">
                    <div class="col-md-12" >
                		<div id="tree_1" class="tree-demo">
                            <ul>
                                <li data-jstree='{ "opened" : false }' *ngFor="let menu of menus"> 
                                	<a (click)="editMenu(menu['id'])"> {{menu['name']}} </a> 
                                    <ul *ngIf="menu['submenu']">
                                        <li data-jstree='{ "opened" : true }' *ngFor="let submenu of getSubMenusById(menu['id'])"> {{submenu['name']}}
                                            <ul *ngIf="submenu['submenuL2']">
                                                <li data-jstree='{ "opened" : true }' *ngFor="let submenul2 of getSubMenusL2ById(submenu['id'])"> {{submenul2['name']}} </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                	</div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade hide" id="delete" tabindex="-1" role="basic" aria-hidden="true" style="padding-right: 17px;" [ngStyle]="{'display': isModal}">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="closeModal()"></button>
                <h4 class="modal-title">Confirmation</h4>
            </div>
            <div class="modal-body"> Are you sure you want to delete Menu / Submenu '{{menu['name']}}'' ? </div>
            <div class="modal-footer">
                <button type="button" class="btn red" (click)="confirmYes()" > Yes </button>
                <button type="button" class="btn dark btn-outline" data-dismiss="modal" (click)="closeModal()">No</button>
            </div>
        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>