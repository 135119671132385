<!-- BEGIN DASHBOARD STATS 1-->
<div class="row d-none hide">
    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
        <a class="dashboard-stat dashboard-stat-v2 blue" href="#">
            <div class="visual">
                <i class="fa fa-comments"></i>
            </div>
            <div class="details">
                <div class="number">
                    <span data-counter="counterup" data-value="1349">0</span>
                </div>
                <div class="desc"> New Feedbacks </div>
            </div>
        </a>
    </div>
    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
        <a class="dashboard-stat dashboard-stat-v2 red" href="#">
            <div class="visual">
                <i class="fa fa-bar-chart-o"></i>
            </div>
            <div class="details">
                <div class="number">
                    <span data-counter="counterup" data-value="12,5">0</span>M$ </div>
                <div class="desc"> Total Profit </div>
            </div>
        </a>
    </div>
    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
        <a class="dashboard-stat dashboard-stat-v2 green" href="#">
            <div class="visual">
                <i class="fa fa-shopping-cart"></i>
            </div>
            <div class="details">
                <div class="number">
                    <span data-counter="counterup" data-value="549">0</span>
                </div>
                <div class="desc"> New Listing </div>
            </div>
        </a>
    </div>
    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
        <a class="dashboard-stat dashboard-stat-v2 purple" href="#">
            <div class="visual">
                <i class="fa fa-globe"></i>
            </div>
            <div class="details">
                <div class="number"> +
                    <span data-counter="counterup" data-value="89"></span>% </div>
                <div class="desc"> Brand Popularity </div>
            </div>
        </a>
    </div>
</div>
<div class="clearfix"></div>
<!-- END DASHBOARD STATS 1-->

<div class="row">
    <div class="col-md-6 col-sm-6">
        <div class="portlet light bordered d-none hide">
            <div class="portlet-title">
                <div class="caption caption-md">
                    <i class="icon-bar-chart font-dark hide"></i>
                    <span class="caption-subject font-dark bold uppercase">Member Activity</span>
                    <span class="caption-helper">weekly stats...</span>
                </div>
                <div class="actions">
                    <div class="btn-group btn-group-devided" data-toggle="buttons">
                        <label class="btn btn-transparent green btn-outline btn-circle btn-sm active">
                            <input type="radio" name="options" class="toggle" id="option1">Today</label>
                        <label class="btn btn-transparent green btn-outline btn-circle btn-sm">
                            <input type="radio" name="options" class="toggle" id="option2">Week</label>
                        <label class="btn btn-transparent green btn-outline btn-circle btn-sm">
                            <input type="radio" name="options" class="toggle" id="option2">Month</label>
                    </div>
                </div>
            </div>
            <div class="portlet-body">
                <div class="row number-stats margin-bottom-30">
                    <div class="col-md-6 col-sm-6 col-xs-6">
                        <div class="stat-left">
                            <div class="stat-chart">
                                <!-- do not line break "sparkline_bar" div. sparkline chart has an issue when the container div has line break -->
                                <div id="sparkline_bar"><canvas width="113" height="55" style="display: inline-block; width: 113px; height: 55px; vertical-align: top;"></canvas></div>
                            </div>
                            <div class="stat-number">
                                <div class="title"> Total </div>
                                <div class="number"> 2460 </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-6 col-xs-6">
                        <div class="stat-right">
                            <div class="stat-chart">
                                <!-- do not line break "sparkline_bar" div. sparkline chart has an issue when the container div has line break -->
                                <div id="sparkline_bar2"><canvas width="107" height="55" style="display: inline-block; width: 107px; height: 55px; vertical-align: top;"></canvas></div>
                            </div>
                            <div class="stat-number">
                                <div class="title"> New </div>
                                <div class="number"> 719 </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="table-scrollable table-scrollable-borderless">
                    <table class="table table-hover table-light">
                        <thead>
                            <tr class="uppercase">
                                <th colspan="2"> MEMBER </th>
                                <th> Earnings </th>
                                <th> CASES </th>
                                <th> CLOSED </th>
                                <th> RATE </th>
                            </tr>
                        </thead>
                        <tbody><tr>
                            <td class="fit">
                                <!-- <img class="user-pic rounded" src="../assets/layout/img/avatar4.jpg">  -->
                            </td>
                            <td>
                                <a href="javascript:;" class="primary-link">Brain</a>
                            </td>
                            <td> $345 </td>
                            <td> 45 </td>
                            <td> 124 </td>
                            <td>
                                <span class="bold theme-font">80%</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="fit">
                                <!-- <img class="user-pic rounded" src="../assets/layout/img/avatar5.jpg">  -->
                            </td>
                            <td>
                                <a href="javascript:;" class="primary-link">Nick</a>
                            </td>
                            <td> $560 </td>
                            <td> 12 </td>
                            <td> 24 </td>
                            <td>
                                <span class="bold theme-font">67%</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="fit">
                                <!-- <img class="user-pic rounded" src="../assets/layout/img/avatar6.jpg">  -->
                            </td>
                            <td>
                                <a href="javascript:;" class="primary-link">Tim</a>
                            </td>
                            <td> $1,345 </td>
                            <td> 450 </td>
                            <td> 46 </td>
                            <td>
                                <span class="bold theme-font">98%</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="fit">
                                <!-- <img class="user-pic rounded" src="../assets/layout/img/avatar7.jpg">  -->
                            </td>
                            <td>
                                <a href="javascript:;" class="primary-link">Tom</a>
                            </td>
                            <td> $645 </td>
                            <td> 50 </td>
                            <td> 89 </td>
                            <td>
                                <span class="bold theme-font">58%</span>
                            </td>
                        </tr>
                    </tbody></table>
                </div>
            </div>
        </div>
    
        <div class="portlet light bordered d-none hide">
            <div class="portlet-title">
                <div class="caption">
                    <i class="icon-equalizer font-dark hide"></i>
                    <span class="caption-subject font-dark bold uppercase">Server Stats</span>
                    <span class="caption-helper">monthly stats...</span>
                </div>
                <div class="tools">
                    <a href="" class="collapse"> </a>
                    <a href="#portlet-config" data-toggle="modal" class="config"> </a>
                    <a href="" class="reload"> </a>
                    <a href="" class="remove"> </a>
                </div>
            </div>
            <div class="portlet-body">
                <div class="row">
                    <div class="col-md-4">
                        <div class="sparkline-chart">
                            <div class="number" id="sparkline_bar5"></div>
                            <a class="title" href="javascript:;"> Network
                                <i class="icon-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                    <div class="margin-bottom-10 visible-sm"> </div>
                    <div class="col-md-4">
                        <div class="sparkline-chart">
                            <div class="number" id="sparkline_bar6"></div>
                            <a class="title" href="javascript:;"> CPU Load
                                <i class="icon-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                    <div class="margin-bottom-10 visible-sm"> </div>
                    <div class="col-md-4">
                        <div class="sparkline-chart">
                            <div class="number" id="sparkline_line"></div>
                            <a class="title" href="javascript:;"> Load Rate
                                <i class="icon-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Churches List --> 
        <div class="portlet light bordered">
            <div class="portlet-title">
                <div class="caption caption-md">
                    <i class="icon-bar-chart font-dark hide"></i>
                    <span class="caption-subject font-dark bold uppercase">Churches List</span>
                    <span class="caption-helper c-margin-l-5">(Total: {{churches.length}} Churches)</span>
                </div>
                <div class="inputs">
                    <div class="portlet-input input-inline input-small ">
                        <div class="input-icon right">
                            <i class="icon-magnifier"></i>
                            <input type="text" class="form-control form-control-solid" placeholder="Search by Name..." (input)="searchChurch($event.target.value)"> 
                        </div>
                    </div>
                </div>
            </div>
            <div class="portlet-body">
                <div class="scroller" style="height: 338px;" data-always-visible="1" data-rail-visible1="0" data-handle-color="#D7DCE2">
                    <div class="table-scrollable table-scrollable-borderless">
                        <table class="table table-hover table-light">
                            <thead>
                                <tr class="uppercase">
                                    <th > Name </th>
                                    <th > Address </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let church of churches">
                                    <td class="col-xs-6"> 
                                        <a href="javascript:;" (click)="viewChurch(church['id'])">{{church['name']}}</a>
                                    </td>
                                    <td class="col-xs-6">{{church['city']}}, {{church['state']}} {{church['zipcode']}} </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <!-- Masses --> 
        <div class="portlet light bordered">
            <div class="portlet-title">
                <div class="caption caption-md">
                    <i class="icon-bar-chart font-dark hide"></i>
                    <span class="caption-subject font-dark bold uppercase">Masses List</span>
                    <span class="caption-helper c-margin-l-5">(Total: {{masses.length}} masses)</span>
                </div>
                <div class="inputs">
                    <div class="portlet-input input-inline input-small ">
                        <div class="input-icon right">
                            <i class="icon-magnifier"></i>
                            <input type="text" class="form-control form-control-solid" placeholder="Search by Name..." > </div>
                    </div>
                </div>
            </div>
            <div class="portlet-body">
                <div class="scroller" style="height: 338px;" data-always-visible="1" data-rail-visible1="0" data-handle-color="#D7DCE2">
                    <div class="table-scrollable table-scrollable-borderless">
                        <table class="table table-hover table-light">
                            <thead>
                                <tr class="uppercase">
                                    <th class="col-xs-5"> Name </th>
                                    <th class="col-xs-5"> Church Name </th>
                                    <th class="col-xs-2"> Youtube Code </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let mass of masses">
                                    <td> {{mass['name']}} </td>
                                    <td> {{mass.church['name']}} - {{mass.church['city']}} {{mass.church['state']}} </td>
                                    <td> {{mass['link']}} </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
     
    <div class="col-md-6 col-sm-6">
        <!-- EMAIL --> 
        <!-- <div class="portlet light bordered">
            <div class="portlet-title">
                <div class="caption caption-md">
                    <i class="icon-bar-chart font-dark hide"></i>
                    <span class="caption-subject font-dark bold uppercase">Email Center</span>
                    <span class="caption-helper hide">45 pending</span>
                </div>
                <div class="inputs">
                    <div class="portlet-input input-inline input-small ">
                        <div class="input-icon right">
                            <i class="icon-magnifier"></i>
                            <input type="text" class="form-control form-control-solid" placeholder="Search by name..." (input)="searchEmail($event.target.value)"> </div>
                    </div>
                </div>
            </div>
            <div class="portlet-body">
                <div class="scroller" style="height: 338px;" data-always-visible="1" data-rail-visible1="0" data-handle-color="#D7DCE2">
                    <div class="general-item-list">
                        <div class="item" *ngFor="let email of emails | slice:0:10">
                            <div class="item-head">
                                <div class="item-details">
                                    <span class="font-14 c-padding-r-10">{{email['name']}}</span>
                                    <span class="item-label">{{email['date']}}</span>
                                </div>
                                <span class="item-status">
                                    <span class="font-yellow-crusta" >
                                        <i class="fa" [ngClass]="email['isMark'] ? 'fa-star' : 'fa-star-o'"></i>
                                    </span>
                                    
                                </span>
                            </div>
                            <div class="item-body"> 
                                <p>{{email['email']}}</p>
                                <a href="javascript:;" (click)="readEmail(email['id'])" class="item-name primary-link" >
                                    <span class="badge badge-empty badge-success" *ngIf="email['isNew']"></span>
                                    {{email['subject']}}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
    
        <!-- Orders List --> 
        <!-- <div class="portlet light bordered">
            <div class="portlet-title">
                <div class="caption caption-md">
                    <i class="icon-bar-chart font-dark hide"></i>
                    <span class="caption-subject font-dark bold uppercase">Orders List</span>
                    <span class="caption-helper">45 pending</span>
                </div>
                <div class="inputs">
                    <div class="portlet-input input-inline input-small ">
                        <div class="input-icon right">
                            <i class="icon-magnifier"></i>
                            <input type="text" class="form-control form-control-solid" placeholder="Search by Order #..." (input)="searchMLS($event.target.value)"> </div>
                    </div>
                </div>
            </div>
            <div class="portlet-body">
                <div class="table-scrollable table-scrollable-borderless">
                    <table class="table table-hover table-light">
                        <thead>
                            <tr class="uppercase">
                                <th colspan="2"> Items </th>
                                <th> Date </th>
                                <th> Order # </th>
                                <th> Status </th>
                            </tr>
                        </thead>
                        <tr *ngFor="let oh of ohs | slice:0:10">
                            <td class="fit">
                                <img class="user-pic rounded" [src]="oh['avatarCode']"> 
                            </td>
                            <td>
                                <a href="javascript:;" class="primary-link">{{oh['assignTo']}}</a>
                            </td>
                            <td> {{oh['date']}} </td>
                            <td>
                                <a href="javascript:;" class="primary-link">{{oh['mls']}}</a>
                            </td>
                            <td> {{oh['active']}} </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div> -->

        <!-- Product --> 
        <!-- <div class="portlet light bordered">
            <div class="portlet-title">
                <div class="caption caption-md">
                    <i class="icon-bar-chart font-dark hide"></i>
                    <span class="caption-subject font-dark bold uppercase">Products List</span>
                    <span class="caption-helper c-margin-l-5">(Total: {{products.length}} items)</span>
                </div>
                <div class="inputs">
                    <div class="portlet-input input-inline input-small ">
                        <div class="input-icon right">
                            <i class="icon-magnifier"></i>
                            <input type="text" class="form-control form-control-solid" placeholder="Search by Name..." (input)="searchProduct($event.target.value)"> </div>
                    </div>
                </div>
            </div>
            <div class="portlet-body">
                <div class="scroller" style="height: 338px;" data-always-visible="1" data-rail-visible1="0" data-handle-color="#D7DCE2">
                    <div class="table-scrollable table-scrollable-borderless" *ngIf="productLoaded">
                        <table class="table table-hover table-light">
                            <thead>
                                <tr class="uppercase">
                                    <th> </th>
                                    <th class="col-xs-9"> Name </th>
                                </tr>
                            </thead>
                            <tr *ngFor="let product of products">
                                <td class="fit">
                                    <img class="user-pic rounded" [src]="uploadUrl + product['image']"> 
                                </td>
                                <td class=""> 
                                    <a href="javascript:;" (click)="viewProduct(product['id'])"> {{product['name']}} </a>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div> -->

        <!-- Customer -->
        <div class="portlet light bordered">
            <div class="portlet-title">
                <div class="caption">
                    <i class="icon-bubble font-dark hide"></i>
                    <span class="caption-subject font-hide bold uppercase">Recent Users</span>
                </div>
                <div class="actions">
                    <div class="btn-group">
                        <a class="btn green-haze btn-outline btn-circle btn-sm" href="javascript:;" data-toggle="dropdown" data-hover="dropdown" data-close-others="true"> Actions
                            <i class="fa fa-angle-down"></i>
                        </a>
                        <ul class="dropdown-menu pull-right">
                            <li>
                                <a href="javascript:;"> Option 1</a>
                            </li>
                            <li class="divider"> </li>
                            <li>
                                <a href="javascript:;">Option 2</a>
                            </li>
                            <li>
                                <a href="javascript:;">Option 3</a>
                            </li>
                            <li>
                                <a href="javascript:;">Option 4</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="portlet-body">
                <div class="row">
                    <div class="col-md-4">
                        <!--begin: widget 1-1 -->
                        <div class="mt-widget-1">
                            <div class="mt-icon">
                                <a href="#">
                                    <i class="icon-plus"></i>
                                </a>
                            </div>
                            <div class="mt-img">
                                <!-- <img src="../assets/layout/img/avatar8.jpg">  -->
                            </div>
                            <div class="mt-body">
                                <h3 class="mt-username">Diana Ellison</h3>
                                <p class="mt-user-title"> Lorem Ipsum is simply dummy text. </p>
                                <div class="mt-stats">
                                    <div class="btn-group btn-group btn-group-justified">
                                        <a href="javascript:;" class="btn font-red">
                                            <i class="icon-bubbles"></i> 1,7k </a>
                                        <a href="javascript:;" class="btn font-green">
                                            <i class="icon-social-twitter"></i> 2,6k </a>
                                        <a href="javascript:;" class="btn font-yellow">
                                            <i class="icon-emoticon-smile"></i> 3,7k </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--end: widget 1-1 -->
                    </div>
                    <div class="col-md-4">
                        <!--begin: widget 1-2 -->
                        <div class="mt-widget-1">
                            <div class="mt-icon">
                                <a href="#">
                                    <i class="icon-plus"></i>
                                </a>
                            </div>
                            <div class="mt-img">
                                <!-- <img src="../assets/layout/img/avatar7.jpg">  -->
                            </div>
                            <div class="mt-body">
                                <h3 class="mt-username">Jason Baker</h3>
                                <p class="mt-user-title"> Lorem Ipsum is simply dummy text. </p>
                                <div class="mt-stats">
                                    <div class="btn-group btn-group btn-group-justified">
                                        <a href="javascript:;" class="btn font-yellow">
                                            <i class="icon-bubbles"></i> 1,7k </a>
                                        <a href="javascript:;" class="btn font-blue">
                                            <i class="icon-social-twitter"></i> 2,6k </a>
                                        <a href="javascript:;" class="btn font-green">
                                            <i class="icon-emoticon-smile"></i> 3,7k </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--end: widget 1-2 -->
                    </div>
                    <div class="col-md-4">
                        <!--begin: widget 1-3 -->
                        <div class="mt-widget-1">
                            <div class="mt-icon">
                                <a href="#">
                                    <i class="icon-plus"></i>
                                </a>
                            </div>
                            <div class="mt-img">
                                <!-- <img src="../assets/layout/img/avatar6.jpg">  -->
                            </div>
                            <div class="mt-body">
                                <h3 class="mt-username">Julia Berry</h3>
                                <p class="mt-user-title"> Lorem Ipsum is simply dummy text. </p>
                                <div class="mt-stats">
                                    <div class="btn-group btn-group btn-group-justified">
                                        <a href="javascript:;" class="btn font-yellow">
                                            <i class="icon-bubbles"></i> 1,7k </a>
                                        <a href="javascript:;" class="btn font-red">
                                            <i class="icon-social-twitter"></i> 2,6k </a>
                                        <a href="javascript:;" class="btn font-green">
                                            <i class="icon-emoticon-smile"></i> 3,7k </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--end: widget 1-3 -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
                    
<!-- <angular-file-uploader
      [config]="afuConfig">
</angular-file-uploader> -->
<div class="row">
    <!-- <div class="col-md-3 col-sm-4 col-xs-12">
        <div class="portlet light bordered">
            <div class="portlet-body form">
                <div class="form-body">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="exampleInputFile1">Selected Image Files</label>
                                <input id="photo" type="file" multiple (change)="onFileSelected($event)" />
                            </div>
                        </div>
                        <div class="col-md-12">
                            <img [src]="sanitizerUrl(img['imgCode'])" class="img-responsive" *ngFor="let img of uploadedFiles" />
                        </div>
                        
                    </div>
                </div>
                <div class="form-actions">
                    <button type="button" class="btn btn-success btn-sm" (click)="uploadAll()">Upload</button>
                    <button type="button" class="btn default">Cancel</button>
                </div>
            </div>
        </div>
    </div> -->
    
    <!-- <div class="col-md-3 col-sm-4 col-xs-12">
        <div class="portlet light bordered">
            <div class="portlet-body form">
                <div class="form-body">
                    <div class="form-group">
                        <label>Enter Text</label>
                        <input type="text" class="form-control" placeholder="Enter content" name="qrcodeString" id="qrcodeString" required minlength="4" [(ngModel)]="qrcodeValue" (change)="generatedQrCode($event)"> 
                    </div>
                    <div class="form-group">
        		        <ngx-qrcode [qrc-element-type]="barcodeElementType" [qrc-value] = "qrcodeValue" href="qrcodeValue"></ngx-qrcode>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    
    <!-- <div class="col-md-3 col-sm-4 col-xs-12">
        <div class="portlet light bordered">
            <div class="portlet-body form">
                <div class="form-body">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="exampleInputFile1">Selected Excel Files</label>
                                <input type="file" style="display: inline-block;" (change)="inComingFiles($event)" placeholder="Upload file" accept=".xlsx" multiple>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-actions">
                    <button type="button" class="btn btn-info btn-sm" (click)="Upload()">Upload</button>
                </div>
                <div class="form-body">
                </div>
            </div>
        </div>
    </div> -->

</div>